import styled from 'styled-components'
import { device } from 'themes/breakpoints'

export const ModulesTableContainer = styled.div`
  max-height: 60%;
  width: 100%;
  overflow: auto;
  padding: 20px 40px;

  .header {
    background-color: ${(props) => props.theme.colors.a};
    display: flex;
    align-items: center;
    padding: 10px;

    .title {
      color: #fff;
      margin: 0px;
    }
  }

  .loading-container {
    background-color: #fff;
    padding: 40px 20px 20px;
  }

  .skill-name {
    font-size: 18px;
    margin: 18px 0px 6px;
  }

  @media ${device.lgDown} {
    padding: 20px;
  }
`

import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  padding: 20px 0px;
  margin: 8px 0px 0px;
  ${(props) =>
    props.isReview &&
    `
    display: flex;
    justify-content: space-between;
  `}

  .activity-container {
    .activity-content {
      display: flex;
      justify-content: space-between;
      animation: 1s ${fadeInAnimation};

      .activity-content-markdown {
        display: grid;

        p {
          font-weight: 500;
        }
      }
    }

    .info-block {
      background-color: #f0f0f0;
      padding: 12px;
      margin: 8px 0px 16px;

      p,
      code {
        font-size: 12px;
        font-weight: 400;
        margin: 0px;
      }
    }
  }

  .activity-actions {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-left: 10px;
  }

  .activity-input {
    margin-bottom: 12px;
  }

  .activity-answers-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    animation: 1s ${fadeInAnimation};

    label {
      margin-bottom: 8px;

      p {
        margin: 0px;
      }
    }
  }

  .activity-status-btn {
    margin-left: 16px;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      color: rgba(0, 0, 0, 0.85);
      border-color: #d9d9d9;
    }

    &[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }

    &.correct {
      color: ${(props) => props.theme.colors.green5};
      border-color: ${(props) => props.theme.colors.green5};

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.green5};
        border-color: ${(props) => props.theme.colors.green5};
      }
    }

    &.incorrect {
      color: ${(props) => props.theme.colors.red4};
      border-color: ${(props) => props.theme.colors.red4};

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.red4};
        border-color: ${(props) => props.theme.colors.red4};
      }
    }

    &.not-actioned {
      color: rgba(0, 0, 0, 0.3);
      border-color: rgba(0, 0, 0, 0.3);

      &:hover,
      &:focus,
      &:active {
        color: rgba(0, 0, 0, 0.3);
        border-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .review-status-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 6px;
    margin-left: 10px;
  }
`

import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BottomActions = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  padding-top: 8px;
  margin: 0px 38px 30px;

  .feedback-btn {
    font-weight: 400;
    padding: 0px;
    margin: 0px 0px 0px;
  }
`

export const size = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export const device = {
  xsDown: `only screen and (max-width: ${size.xs + 1})`,
  smDown: `only screen and (max-width: ${size.sm})`,
  mdDown: `only screen and (max-width: ${size.md})`,
  lgDown: `only screen and (max-width: ${size.lg})`,
  xlDown: `only screen and (max-width: ${size.xl})`,
  xxlDown: `only screen and (max-width: ${size.xxl})`,

  xsUp: `only screen and (min-width: ${size.xs + 1})`,
  smUp: `only screen and (min-width: ${size.sm})`,
  mdUp: `only screen and (min-width: ${size.md})`,
  lgUp: `only screen and (min-width: ${size.lg})`,
  xlUp: `only screen and (min-width: ${size.xl})`,
  xxlUp: `only screen and (min-width: ${size.xxl})`,
}

import styled from 'styled-components'
import { device } from 'themes/breakpoints'

export const MainContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .header {
    width: 100%;
    background-color: #fff;
    position: sticky;
    top: 0px;
    padding: 20px;

    .ant-breadcrumb {
      margin-bottom: 16px;

      .back-icon {
        font-size: 10px;
        margin-right: 8px;
      }

      ol {
        flex-wrap: initial;

        li {
          white-space: nowrap;
        }

        li:not(:first-child) {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        > span:last-child a {
          color: rgba(0, 0, 0, 0.45);
          transition: color 0.3s;

          &:hover {
            color: #40a9ff;
          }
        }
      }
    }

    .info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .module-name {
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0px;
      }

      .buttons-container {
        margin-left: 8px;

        button:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
`

export const SecondaryContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .lab-content {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.a300};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-container {
      width: 400px;
    }
  }

  .mobile-message {
    width: 100%;
    color: #fff;
    background-color: #3390ff;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin: 0px;

    p {
      font-size: 13px;
      margin: 0px;

      &:first-child {
        font-weight: 500;
      }
    }
    svg {
      font-size: 32px;
      margin-right: 14px;
    }
  }

  @media ${device.lgDown} {
    .lab-content {
      display: none;
    }

    .mobile-message {
      display: flex;
    }
  }
`

import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'activities',
  initialState: {
    error: null,
  },
  reducers: {
    SET_ACTIVITY_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { SET_ACTIVITY_ERROR } = slice.actions
export default slice.reducer

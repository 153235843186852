import styled from 'styled-components'

export const Container = styled.div`
  max-width: 600px;
  text-align: center;

  svg {
    color: ${(props) => props.theme.colors.gold6};
    font-size: 70px;
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 0px;
  }

  .text-body {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 24px;
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 360px;
  text-align: center;
  margin: 8px auto 0px;

  .ant-btn {
    width: 100%;
    font-size: 16px;
    margin: 8px 0px;
  }
`

import API from 'services/client'
import { apiErrorHandler } from 'helpers/errorHandlers'

const postLtiScore = async (launchId) => {
  const route = `lti/score/${launchId}`

  const API_2 = API
  API_2.defaults.baseURL = API_2.defaults.baseURL.slice(0, -7)

  return API_2.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { postLtiScore }

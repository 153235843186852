import React from 'react'
import { useSelector } from 'react-redux'
import { Pagination } from 'antd'
import { Container } from './styles'

const ModulePageSelector = ({ page, setPage }) => {
  const { currentModule: module } = useSelector((state) => state.modules)
  const pages = module?.module_pages
  const { progress } = module.user_status.last_module_attempt.user_status
  const parsedProgress = Math.round(progress * 100, 2)

  const handleSelectPage = (newPage) => {
    if (newPage !== parseInt(page)) {
      setPage(newPage)
    }
  }

  return (
    <Container id="module-page-bottom-panel" isFinished={parsedProgress === 100}>
      <div id="module-bottom-progress-bar" className="progress">
        <p>{parsedProgress}% tasks complete</p>
        <div className="percentage" style={{ width: `${parsedProgress}%` }} />
      </div>

      <div className="nav">
        {page && (
          <Pagination
            id="module-page-selector-pages"
            defaultCurrent={parseInt(page)}
            total={pages.length}
            defaultPageSize={1}
            onChange={(newPage) => handleSelectPage(newPage)}
          />
        )}
      </div>
    </Container>
  )
}

export default ModulePageSelector

import axios from 'axios'
import { apiURL } from 'helpers/env'

const API = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
    post: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
  emulateJSON: true,
})

export default API

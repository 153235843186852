import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Button from 'components/Button'
import { getVoucher, redeemVoucher } from 'store/vouchers/actions'
import { ContentSelectionBox } from './styles'

const ContentSelection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentVoucher: voucher } = useSelector((state) => state.vouchers)

  const [isLoading, setIsLoading] = useState(true)

  const onLoad = useCallback(async () => {
    if (voucher?.code) {
      await dispatch(getVoucher(voucher?.code))
    }
    setIsLoading(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const commitSelection = async () => {
    setIsLoading(true)

    const contentId = voucher?.content?.available?.[0]?.id
    const redeemData = {
      voucherCode: voucher?.code,
      contentIds: [contentId],
    }
    await dispatch(redeemVoucher(redeemData, () => navigate('/')))

    setIsLoading(false)
  }

  useEffect(() => {
    onLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentSelectionBox>
      {voucher?.content?.available.map((c) => (
        <div key={c.id} className="title-container">
          <h5 className="title">{c.name}</h5>
          <h5 className="subtitle">
            Valid till: {dayjs().add(voucher?.license_duration_in_days, 'days').format('LL')}
          </h5>
        </div>
      ))}

      <Button type="primary" size="large" onClick={commitSelection} loading={isLoading}>
        Confirm selection
      </Button>
    </ContentSelectionBox>
  )
}

export default ContentSelection

import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const useQueryParam = (paramName) => {
  const [param, setParam] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const allParams = useMemo(() => new URLSearchParams(location.search), [location])

  const setParamValue = (newValue) => {
    setParam(newValue)
    allParams.set(paramName, newValue)
    navigate(`${location.pathname}?${allParams}`, { replace: true })
  }

  useEffect(() => {
    const paramValue = allParams.get(paramName)
    setParam(paramValue)
  }, [allParams, paramName])

  return [param, setParamValue]
}

export default useQueryParam

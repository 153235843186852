import styled from 'styled-components'
import { device } from 'themes/breakpoints'

export const Card = styled.div`
  max-width: 840px;
  width: 100%;
  background-color: #fff;
  padding: 40px;
  margin: 0 auto;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  .module-info {
    .main-pretitle {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 8px;
    }

    .main-title {
      border-bottom: 0.5px solid #bfbfbf;
      padding-bottom: 10px;
      margin-bottom: 30px;
    }

    .report-section {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .section-title {
        color: #bfbfbf;
        font-weight: 500;
        padding-bottom: 4px;
        border-bottom: 0.5px solid #bfbfbf;
      }

      .activities-chart {
        height: 64px;
        margin-bottom: 32px;
      }

      .section-row {
        display: flex;
        flex-wrap: wrap;

        @media ${device.mdDown} {
          display: block;
        }

        .row-item {
          padding: 0px 30px 0px 0px;
          margin-bottom: 30px;

          .label-text {
            color: #8c8c8c;
            margin-bottom: 4px;
          }

          .value-text {
            font-weight: 500;
            margin: 0px;
          }
        }

        &.i25 {
          .row-item  {
            flex: 0 0 25%;
          }
        }

        &.i33 {
          .row-item  {
            flex: 0 0 33.3%;
          }
        }

        &.i50 {
          .row-item  {
            flex: 0 0 50%;
          }
        }
      }
    }
  }
`

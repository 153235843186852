import styled from 'styled-components'
import { Dropdown as AntDropdown, Menu as AntMenu } from 'antd'

export const Dropdown = styled(AntDropdown)`
  &.device-selector-btn {
    /* background-color: #bae7ff;
    border: none; */
  }
`

export const Menu = styled(AntMenu)`
  .device-menu-item {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 6px;

    .icon {
      width: 30px;
      margin-right: 16px;
    }

    .device-info {
      width: 100%;
      margin-right: 20px;

      .device-name {
        margin: 0px;

        .status {
          width: 10px;
          height: 10px;
          background-color: red;
          display: inline-block;
          border-radius: 50%;
          margin-left: 4px;

          &.started {
            background-color: #52c41a;
          }

          &.starting,
          &.stopping,
          &.restarting {
            background-color: #faad14;
          }

          &.stopped {
            background-color: #ff4d4f;
          }
        }
      }

      .device-ip {
        color: #8c8c8c;
        font-size: 12px;
        margin: -4px 0px 0px;
      }
    }

    .device-actions {
      display: flex;

      .ant-btn {
        margin: 0 2px;
      }
    }
  }

  .is-selected {
    background-color: #eee;

    .device-name {
    }
  }
`

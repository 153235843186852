import API from 'services/client'
import { apiErrorHandler } from 'helpers/errorHandlers'

const fetchLab = async (labId) => {
  const route = `labs/${labId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postLabAllocate = async (labId, data) => {
  const route = `labs/${labId}/sessions/allocate`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postLabDeallocate = async (labId, sessionId, deallocateReason) => {
  const route = `labs/${labId}/sessions/${sessionId}/deallocate`
  const body = {
    deallocate_reason: deallocateReason,
  }
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postLabPing = async (labId, sessionId, data) => {
  const route = `labs/${labId}/sessions/${sessionId}/ping`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postSessionStart = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/start`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postSessionStop = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/stop`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postSessionRestart = async (labSessionId, deviceId) => {
  const route = `sessions/${labSessionId}/devices/${deviceId}/restart`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  fetchLab,
  postLabAllocate,
  postLabDeallocate,
  postLabPing,
  postSessionStart,
  postSessionStop,
  postSessionRestart,
}

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress } from 'antd'
import getRandomInt from 'utils/getRandomInt'
import getRandomQuote from 'utils/getRandomQuote'
import { Content } from './styles'

import Quote from 'assets/images/quote.svg'

const LabLoading = ({ loadTime = 10000 }) => {
  const [timeLeft, setTimeLeft] = useState(loadTime)
  const [deviceLoadTimes, setDeviceLoadTimes] = useState([])
  const [randomQuote, setRandomQuote] = useState({})
  const { currentLab: lab } = useSelector((state) => state.labs)
  const { allocated_session: labSession } = lab
  const { devices: deviceSessions } = labSession

  const step = 1000

  setTimeout(function () {
    if (timeLeft) {
      setTimeLeft(timeLeft - step)
    }
  }, step)

  useEffect(() => {
    setRandomQuote(getRandomQuote())

    let loadTimes = []
    deviceSessions?.forEach((ds) => {
      const randomTotalTime = loadTime + getRandomInt(1, 10) * 1000
      loadTimes.push(randomTotalTime)
    })
    setDeviceLoadTimes(loadTimes)
  }, [deviceSessions]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <h3 className="title">Setting up your lab environment</h3>
      <p className="subtitle">In a few seconds you will be able to start working on it</p>

      <div className="device-loading-container">
        {deviceSessions?.map((ds, i) => {
          const perc = parseInt(((deviceLoadTimes[i] - timeLeft) / deviceLoadTimes[i]) * 100)
          return (
            <div key={ds.id} className="device-loading-progress">
              <p>
                Device {i + 1}: <b>{ds.device.name}</b>
              </p>
              <Progress percent={perc} status="active" />
            </div>
          )
        })}
      </div>

      <div className="quote-container">
        <img src={Quote} alt="" />
        <h5>{randomQuote['quote']}</h5>
        <p>— {randomQuote['author']}</p>
      </div>
    </Content>
  )
}

export default LabLoading

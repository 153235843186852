import styled from 'styled-components'

export const Card = styled.div`
  display: inline-block;
  margin: 8px;

  .content {
    width: fit-content;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);

    svg {
      color: ${(props) => props.theme.colors.a300};
      font-size: 36px;
      margin-right: 6px;
    }

    p {
      font-weight: 500;
      display: inline-block;
      margin: 0px;
    }
  }
`

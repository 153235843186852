import React from 'react'
import { Tooltip } from 'antd'
import { Blob as StyledBlob } from './styles'

const Blob = ({ text, onClick, color = 'red' }) => {
  return (
    <Tooltip title={text}>
      <StyledBlob className={color} onClick={onClick} />
    </Tooltip>
  )
}

export default Blob

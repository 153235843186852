import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import useQueryParam from 'hooks/useQueryParam'
import InputActivity from './components/InputActivity'
import MultipleChoiceActivity from './components/MultipleChoiceActivity'

const Activity = ({ activity, isReview }) => {
  const [launchId] = useQueryParam('launch_id')
  const [animationKey, setAnimationKey] = useState()
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [countryCode])

  if (activity?.activity_type === 'input') {
    return <InputActivity key={animationKey} activity={activity} isReview={isReview} launchId={launchId} />
  }

  if (activity?.activity_type === 'multiple_choice') {
    return <MultipleChoiceActivity key={animationKey} activity={activity} isReview={isReview} launchId={launchId} />
  }

  return <Skeleton active loading={!activity} title={false} paragraph={{ rows: 4 }} />
}

export default Activity

import styled from 'styled-components'

export const ContentSelectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-container {
    max-width: 620px;
    width: 100%;
    background-color: #fff;
    padding: 16px 24px;
    margin: 18px 0px 30px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .subtitle {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px;
  }
`

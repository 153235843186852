import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GlobalLoader from 'components/GlobalLoader'
import { setAppError, setAppNotification } from 'store/app/actions'
import { tryAutoLogin } from 'store/users/actions'
import Router from 'router'
import Theme from 'themes/base'
import { initializeDayjs } from 'utils/dayjs/index'
initializeDayjs()

const App = () => {
  const [isPageLoading, setPageLoading] = useState(true)
  const { appError, appNotification } = useSelector((state) => state.app)
  const { isAuthenticated } = useSelector((state) => state.users)
  const dispatch = useDispatch()

  const onLoad = useCallback(async () => {
    await dispatch(tryAutoLogin())
    setPageLoading(false)
  }, [dispatch])

  if (appError) {
    // toastError(appError)
    dispatch(setAppError(null))
  }

  if (appNotification) {
    // toastNotification(appNotification)
    dispatch(setAppNotification(null))
  }

  useEffect(() => {
    if (isAuthenticated === null) {
      onLoad()
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return <Theme>{isPageLoading ? <GlobalLoader /> : <Router />}</Theme>
}

export default App

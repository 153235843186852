import styled from 'styled-components'
import { device } from 'themes/breakpoints'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;

  .main-body {
    max-width: 900px;
    height: 100%;
    padding: 8% 24px 0px;
    margin: 0 auto;

    .section-title {
      font-size: 28px;
      border-bottom: 1px solid #000;
      padding-bottom: 10px;
    }

    .titles-container {
      padding-bottom: 20px;

      .loading-container {
        text-align: center;
        padding: 20px;

        .loading-text {
          margin-left: 8px;
        }
      }

      .title-container {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin: 20px 0;

        &:not(:last-child)  {
          border-bottom: 1px solid #f0f0f0;
        }

        .icon {
          min-width: 32px;
          background-color: ${(props) => props.theme.colors.blue2};
          color: ${(props) => props.theme.colors.blue6};
          margin-right: 16px;
        }

        .logo {
          &.dataplus {
            width: 92px;
            margin-right: 24px;
          }
        }

        .title-info {
          width: 100%;

          .title-name {
            color: #222;
            margin-bottom: 14x;
          }

          .description {
            max-height: 124px;
            color: rgba(0, 0, 0, 0.45);
            text-align: left;
            overflow: auto;
            margin-right: 16px;

            > p {
              margin-bottom: 0px;
            }
          }
        }

        @media ${device.lgDown} {
          display: block;

          .icon {
            display: none;
          }
        }
      }
    }
  }
`

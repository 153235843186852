import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal } from 'antd'
import { deallocateLab } from 'store/labs/actions'

const DeviceStop = ({ labId, labSessionId, disabled, text, type, callback }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { currentLab: lab } = useSelector((state) => state.labs)
  const { allocated_session: labSession } = lab

  const handleStopLab = async () => {
    setIsLoading(true)
    await dispatch(deallocateLab(labId || lab?.id, labSessionId || labSession?.id, 'manual'))
    setIsLoading(false)

    if (callback) {
      callback()
    }

    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        id="lab-stop-btn"
        type={type || 'primary'}
        danger
        className="stop-btn"
        onClick={() => setIsModalVisible(true)}
        disabled={disabled}
      >
        {text || 'Stop lab'}
      </Button>

      <Modal
        title="Are you sure you want to stop the lab?"
        visible={isModalVisible}
        onOk={handleStopLab}
        onCancel={() => setIsModalVisible(false)}
        okText="Stop lab"
        cancelText="Keep working"
        okButtonProps={{ danger: true, disabled: isLoading, loading: isLoading }}
      >
        <p>Stopping the lab will close all the environments and devices.</p>
        <p>Unsaved changes will be lost.</p>
      </Modal>
    </>
  )
}

export default DeviceStop

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Skeleton } from 'antd'
import { ExperimentOutlined } from '@ant-design/icons'
import useQueryParam from 'hooks/useQueryParam'
import Layout1 from 'layouts/Layout1'
import IconCard from 'components/IconCard'
import ModulesTable from './components/ModulesTable'
import { Container } from './styles'
import { getTitle } from 'store/titles/actions'
import { getAllocatedModules } from 'store/modules/actions'

const TitlePage = () => {
  const dispatch = useDispatch()
  const [inviteCode] = useQueryParam('invite_code')
  const [isLoading, setIsLoading] = useState(true)
  const [animationKey, setAnimationKey] = useState()
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const { currentTitle: title, error } = useSelector((state) => state.titles)
  const { titleId } = useParams()
  const navigate = useNavigate()

  const onLoad = async () => {
    setIsLoading(true)

    await dispatch(
      getTitle(titleId, async () => {
        setIsLoading(false)
      }),
    )
    await dispatch(getAllocatedModules())
  }

  useEffect(() => {
    onLoad()

    if (error) {
      // console.log('error')
      navigate('/', { replace: true })
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inviteCode) {
      localStorage.setItem('invite_code', inviteCode)
    }
  }, [inviteCode])

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [countryCode])

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      showWhitelabel
      mainContent={
        <Container>
          <div className="info-container">
            {!isLoading && (
              <>
                {title && <h2 className="title">{title?.name?.[countryCode] || title?.name}</h2>}
                <div
                  key={animationKey}
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: title?.content_html?.[countryCode] || title?.content_html,
                  }}
                />
                <div className="cards-container">
                  {title?.modules?.length && (
                    <IconCard icon={<ExperimentOutlined />} text={`${title?.modules?.length} Lab guides`} />
                  )}
                </div>
              </>
            )}
            <Skeleton active paragraph={{ rows: 8 }} loading={isLoading} />
          </div>

          <ModulesTable isLoading={isLoading} modules={title?.modules} />
        </Container>
      }
    />
  )
}

export default TitlePage

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: #f0f2f5 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-bar {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;

    .back-icon {
      font-size: 10px;
      margin-right: 8px;
    }
  }

  .card-container {
    width: 100%;
    overflow: auto;
    padding: 20px 20px 30px;
  }
`

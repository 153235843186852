import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .progress-title {
    margin-bottom: 30px;
  }

  .device-loading-container {
    width: 100%;
    margin: 30px 0px 60px;

    .device-loading-progress {
      margin-bottom: 20px;

      p {
        margin: 0px;
      }
    }
  }

  .quote-container {
    position: relative;

    img {
      width: 72px;
      opacity: 0.08;
      position: absolute;
      top: -20px;
      left: -30px;
    }

    h5 {
      color: #000;
      font-style: italic;
    }
  }
`

import { TITLE_UPDATED } from 'store/titles'
import {
  MODULE_UPDATED,
  MODULE_REPORT_UPDATED,
  ALLOCATED_MODULES_UPDATED,
  PAGE_UPDATED,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
} from 'store/modules'
import { fetchModule, postModuleAttempt, fetchModuleReport, fetchAllocatedModules } from 'services/modules'

const getModule = (moduleId, cb) => async (dispatch) => {
  try {
    const module = await fetchModule(moduleId)
    dispatch(MODULE_UPDATED(module))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const createModuleAttempt = (moduleId, shouldResetActivities) => async (dispatch, getState) => {
  try {
    const { currentTitle } = getState().titles
    const { currentModule } = getState().modules

    const attempt = await postModuleAttempt(moduleId)
    const auxTitle = {
      ...currentTitle,
      modules: currentTitle?.modules?.map((m) => {
        if (m.id === moduleId) {
          const auxModule = {
            ...m,
            user_status: {
              ...m.user_status,
              last_module_attempt: attempt,
            },
          }

          return auxModule
        }

        return m
      }),
    }

    dispatch(TITLE_UPDATED(auxTitle))

    if (currentModule) {
      let auxModule = {
        ...currentModule,
        user_status: {
          ...currentModule.user_status,
          last_module_attempt: attempt,
        },
      }

      if (shouldResetActivities) {
        auxModule.module_pages = auxModule.module_pages.map((p) => {
          const auxPage = {
            ...p,
            activities: p.activities.map((a) => {
              const auxActivity = {
                ...a,
                user_status: null,
                hint_html: null,
                solution_html: null,
              }

              return auxActivity
            }),
          }
          return auxPage
        })
      }

      dispatch(MODULE_UPDATED(auxModule))
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const getModuleReport = (moduleId, moduleAttemptId) => async (dispatch, getState) => {
  try {
    const module = await fetchModuleReport(moduleId, moduleAttemptId)
    dispatch(MODULE_REPORT_UPDATED(module))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const getAllocatedModules = () => async (dispatch) => {
  try {
    const module = await fetchAllocatedModules()
    dispatch(ALLOCATED_MODULES_UPDATED(module))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const selectPage = (newPage) => (dispatch) => {
  dispatch(PAGE_UPDATED(newPage))
}

const resetModulesState = () => (dispatch) => {
  dispatch(RESET_MODULES_STATE())
}

export { getModule, createModuleAttempt, getModuleReport, getAllocatedModules, selectPage, resetModulesState }

import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

const DeviceIframe = ({ url, title }) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true)

  useEffect(() => {
    setIsIframeLoading(true)
  }, [url, title])

  return (
    <>
      {isIframeLoading && (
        <div style={{ position: 'absolute', textAlign: 'center' }}>
          <Spin size="large" />
          <h3 className="title">Loading lab content</h3>
        </div>
      )}

      <iframe key={url} src={url} onLoad={() => setIsIframeLoading(false)} title={title} frameBorder="0"></iframe>
    </>
  )
}

export default DeviceIframe

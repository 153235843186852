import React from 'react'
import { Card } from './styles'

const IconCard = ({ icon, text }) => {
  return (
    <Card>
      <div className="content">
        {icon}
        <p>{text}</p>
      </div>
    </Card>
  )
}

export default IconCard

import { VOUCHER_UPDATED, SET_VOUCHER_ERROR, RESET_VOUCHERS_STATE } from 'store/vouchers'
import { showToast } from 'utils/toast'
import { USER_PROFILE_UPDATED } from 'store/users'
import { fetchVoucher, postVoucherValidate, postVoucherSignup, postVoucherRedeem } from 'services/vouchers'

const getVoucher = (voucherCode) => async (dispatch) => {
  try {
    const voucher = await fetchVoucher(voucherCode)
    dispatch(VOUCHER_UPDATED(voucher))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const validateVoucher = (data) => async (dispatch) => {
  try {
    const voucher = await postVoucherValidate(data)
    dispatch(VOUCHER_UPDATED(voucher))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const signupWithVoucher = (data) => async (dispatch) => {
  try {
    const userProfile = await postVoucherSignup(data)
    dispatch(USER_PROFILE_UPDATED({ ...userProfile, justCreated: true }))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const redeemVoucher = (data, cb) => async (dispatch, getState) => {
  try {
    const { currentVoucher } = getState().vouchers

    await postVoucherRedeem(data)

    const auxVoucher = {
      ...currentVoucher,
      redeemed_slots: currentVoucher?.redeemed_slots + (data?.contentIds?.length || 0),
    }

    dispatch(VOUCHER_UPDATED(auxVoucher))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const resetVouchersState = () => (dispatch) => {
  dispatch(RESET_VOUCHERS_STATE())
}

export { getVoucher, validateVoucher, signupWithVoucher, redeemVoucher, resetVouchersState }

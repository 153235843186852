import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Progress, Skeleton } from 'antd'
import { Bullet } from '@ant-design/plots'
import Activity from 'pages/ModulePage/components/Activity'
import { Card } from './styles'

const ReportCard = ({ isLoading }) => {
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule: module, moduleReport } = useSelector((state) => state.modules)

  const started = moduleReport?.started_at ? dayjs(moduleReport?.started_at).format('MM/DD/YYYY HH:mm') : '—'
  const finished = moduleReport?.finished_at ? dayjs(moduleReport?.finished_at).format('MM/DD/YYYY HH:mm') : '—'
  const ellapsedTimeMin = Math.round((moduleReport?.ellapsed_time_in_secs || 0) / 60, 2)
  const progress = Math.round((moduleReport?.user_status?.progress || 0) * 100, 2)
  const score = Math.round((moduleReport?.user_status?.scoring || 0) * 100, 2)
  const totalActivities = moduleReport?.user_status?.total_activities
  const passedActivities = moduleReport?.user_status?.passed_activities
  const failedActivities = moduleReport?.user_status?.failed_activities
  const submittedActivities = moduleReport?.user_status?.submitted_activities
  const hintsRequested = moduleReport?.user_status?.hints_requested
  const solutionsRequested = moduleReport?.user_status?.solutions_requested

  const orgName = userProfile?.organization?.name || '—'
  const userFullname = `${userProfile?.first_name} ${userProfile?.last_name}`
  const email = userProfile?.email
  const username = userProfile?.username

  const renderActivities = () => {
    return moduleReport?.activities.map((a) => {
      return <Activity key={a.id} activity={a} isReview={true} />
    })
  }

  const activitiesChartConfig = {
    data: [
      {
        ranges: [totalActivities],
        measures: [passedActivities || null, failedActivities || null, totalActivities - submittedActivities || null],
        target: false,
      },
    ],
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    color: {
      range: ['transparent'],
      measure: ['#95de64', '#ff7875', 'rgb(229, 229, 229)'],
      target: ['transparent'],
    },
    label: {
      measure: {
        position: 'middle',
        style: {
          fill: '#000',
          fontSize: 14,
        },
      },
    },
    xAxis: false,
    yAxis: false,
    tooltip: false,
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: 'correct',
          name: 'Correct',
          marker: {
            style: {
              fill: '#95de64',
            },
          },
        },
        {
          value: 'incorrect',
          name: 'Incorrect',
          marker: {
            style: {
              fill: '#ff7875',
            },
          },
        },
        {
          value: 'not-actioned',
          name: 'Not actioned',
          marker: {
            style: {
              fill: '#ccc',
            },
          },
        },
      ],
    },
  }

  return (
    <Card>
      <div className="module-info">
        {module?.title && (
          <>
            <p className="main-pretitle">Module Report</p>
            <h5 className="main-title">{module?.name?.[countryCode] || module?.name}</h5>
          </>
        )}

        <div className="report-section">
          {!isLoading ? (
            <>
              <div className="section-row i33">
                <div className="row-item">
                  <p className="label-text">Started</p>
                  <p className="value-text">{started}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Finished</p>
                  <p className="value-text">{finished}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Elapsed time (min)</p>
                  <p className="value-text">{ellapsedTimeMin}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Organization</p>
                  <p className="value-text">{orgName}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Progress</p>
                  <Progress
                    percent={progress}
                    strokeLinecap="square"
                    strokeColor={progress < 100 ? '#1890ff' : '#5CDBD3'}
                    trailColor={'#e5e5e5'}
                    success={{ percent: 0 }}
                  />
                </div>
                <div className="row-item">
                  <p className="label-text">Score</p>
                  <Progress
                    percent={score}
                    strokeLinecap="square"
                    strokeColor={score < 100 ? '#1890ff' : '#5CDBD3'}
                    trailColor={'#e5e5e5'}
                    success={{ percent: 0 }}
                  />
                </div>
              </div>
            </>
          ) : (
            <Skeleton active paragraph={{ rows: 7 }} />
          )}
        </div>

        <div className="report-section">
          <p className="section-title">User Details</p>
          {!isLoading ? (
            <>
              <div className="section-row i33">
                <div className="row-item">
                  <p className="label-text">Name</p>
                  <p className="value-text">{userFullname}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Email</p>
                  <p className="value-text">{email}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Username</p>
                  <p className="value-text">{username}</p>
                </div>
              </div>
            </>
          ) : (
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          )}
        </div>

        <div className="report-section">
          <p className="section-title">Activities</p>
          {!isLoading ? (
            <>
              <div className="section-row i33">
                <div className="row-item">
                  <p className="label-text">Total activities</p>
                  <p className="value-text">{totalActivities}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Hints viewed</p>
                  <p className="value-text">{hintsRequested}</p>
                </div>
                <div className="row-item">
                  <p className="label-text">Solutions viewed</p>
                  <p className="value-text">{solutionsRequested}</p>
                </div>
              </div>

              <div className="activities-chart">
                <Bullet {...activitiesChartConfig} />
              </div>
            </>
          ) : (
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          )}

          {!isLoading ? renderActivities() : <Skeleton active title={false} paragraph={{ rows: 4 }} />}
        </div>
      </div>
    </Card>
  )
}

export default ReportCard

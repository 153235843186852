import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { showToast } from 'utils/toast'
import Layout1 from 'layouts/Layout1'
import ReportCard from './components/ReportCard'
import { getModule, getModuleReport } from 'store/modules/actions'
import { Container } from './styles'

const ModuleReportPage = () => {
  const dispatch = useDispatch()
  const { moduleId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const { currentModule: module, moduleReport, error } = useSelector((state) => state.modules)
  const moduleAttemptId = module?.user_status?.last_module_attempt?.id

  const handleModuleLoad = async () => {
    await dispatch(getModule(moduleId))
  }

  const handleReportLoad = async () => {
    if (module && !moduleReport) {
      await dispatch(getModuleReport(moduleId, moduleAttemptId))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleModuleLoad()

    if (error) {
      showToast('There was an error loading module content', 'error')
    }
  }, [moduleId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleReportLoad()
  }, [module]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      mainContent={
        <Container>
          <div className="top-bar">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/module/${module?.id}`}>
                  <ArrowLeftOutlined className="back-icon" />
                  Back to Lab guide
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            {/* <Button type="primary" disabled>
              Save as PDF
            </Button> */}
          </div>

          <div className="card-container">
            <ReportCard isLoading={isLoading} />
          </div>
        </Container>
      }
    ></Layout1>
  )
}

export default ModuleReportPage

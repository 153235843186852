import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, Button, Spin } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import getRandomInt from 'utils/getRandomInt'
import { selectView } from 'store/labs/actions'
import { startDeviceSession } from 'store/labs/actions'
import DeviceSelector from '../DeviceSelector'
import DeviceIframe from '../DeviceIframe'
import DeviceStop from '../DeviceStop'
import LabLoading from '../LabLoading'
import { Container } from './styles'

const DeviceContent = ({ isFirstLoad, setIsFirstLoad }) => {
  const dispatch = useDispatch()
  const { currentLab: lab, currentDevice: deviceSession, currentView } = useSelector((state) => state.labs)
  const { allocated_session: labSession } = lab
  const [shouldWait, setShouldWait] = useState(false)
  const [loadTime] = useState(getRandomInt(17, 20) * 1000)
  const selectedTab = deviceSession?.views?.findIndex((v) => v === currentView)

  const handleStartDevice = async () => {
    await dispatch(startDeviceSession(labSession?.id, deviceSession.id))
  }

  useEffect(() => {
    if (deviceSession?.views?.[0] && !currentView) {
      dispatch(selectView(deviceSession?.views?.[0]))
    }
  }, [deviceSession]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFirstLoad) {
      setShouldWait(true)
      setIsFirstLoad(false)
    }

    setTimeout(() => {
      setShouldWait(false)
    }, loadTime)
  }, [isFirstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Tabs
        id="lab-top-panel"
        activeKey={String(selectedTab)}
        tabBarExtraContent={{
          left: <DeviceSelector disabled={shouldWait} />,
          right: <DeviceStop disabled={shouldWait} />,
        }}
        onTabClick={(i) => dispatch(selectView(deviceSession?.views?.[i]))}
      >
        {deviceSession?.views &&
          deviceSession?.views.map((v, i) => <Tabs.TabPane key={i} tab={v.name} disabled={shouldWait} />)}
      </Tabs>

      <div className="content">
        {shouldWait && <LabLoading loadTime={loadTime} />}

        {!shouldWait && ['starting', 'stopping', 'restarting'].includes(deviceSession?.status) && (
          <>
            <Spin size="large" />
            <h3 className="title">Device is {deviceSession?.status}</h3>
          </>
        )}

        {['stopped', 'error'].includes(deviceSession?.status) && (
          <>
            <ExclamationCircleFilled className="device-stopped-icon" />
            <h3 className="title">The device is stopped</h3>
            <Button type="link" onClick={handleStartDevice}>
              Click here to turn it on
            </Button>
          </>
        )}

        {['started'].includes(deviceSession?.status) && !shouldWait && (
          <>{currentView?.url && <DeviceIframe url={currentView?.url} title={deviceSession?.name} />}</>
        )}
      </div>
    </Container>
  )
}

export default DeviceContent

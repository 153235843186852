import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Modal, Progress, Tooltip } from 'antd'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Button from 'components/Button'
import Blob from 'components/Blob'
import { createModuleAttempt } from 'store/modules/actions'
import { Item } from './styles'

const ModuleTableItem = ({ module, number, isOpen = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const { allocatedModules } = useSelector((state) => state.modules)

  const [open, setOpen] = useState(isOpen)
  const [isJustStarted, setIsJustStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { user_status: { last_module_attempt } = {} } = module
  const isStarted = !!last_module_attempt
  const moduleProgress = (last_module_attempt?.user_status?.progress || 0) * 100
  const isNotStarted = moduleProgress === 0
  const isFinished = moduleProgress === 100
  const isAllocated = allocatedModules?.some((m) => m.module_id === module.id)

  const handleStartModule = async () => {
    setIsLoading(true)
    await dispatch(createModuleAttempt(module?.id))
    setIsJustStarted(true)
    setIsLoading(false)
  }

  const displayLabMainActionButton = () => {
    if (isStarted) {
      return (
        <Button className="main-btn" type="primary">
          <Link to={`/module/${module?.id}`}>Continue</Link>
        </Button>
      )
    }

    return (
      <Button className="main-btn" type="primary" onClick={handleStartModule} disabled={isLoading} loading={isLoading}>
        Start
      </Button>
    )
  }

  const resetConfirmationModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to reset this Lab guide?',
      content: "This action cannot be undone and you'll lose all your Lab Guide progress.",
      icon: <ExclamationCircleOutlined />,
      okText: 'Reset Lab',
      cancelText: 'Keep working',
      okButtonProps: { danger: true, primary: true, disable: isLoading, loading: isLoading },
    })
  }

  useEffect(() => {
    // auto-redirect to module view
    if (!!module && isJustStarted) {
      navigate(`/module/${module.id}`, { replace: true })
    }
  }, [isJustStarted]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Item isNotStarted={isNotStarted} isFinished={isFinished}>
        <div className={`collapse-header ${'init'} ${open && 'is-open'}`}>
          <div className="item-info">
            <div className={`collapse-title ${open}`}>
              <Tooltip title={`Progress: ${moduleProgress}%`} placement="right">
                <Progress
                  className="lab-progress"
                  type="circle"
                  percent={moduleProgress}
                  format={() => (isFinished ? <CheckOutlined /> : number)}
                  width={30}
                  strokeColor={isFinished ? '#13c2c2' : '#1890ff'}
                  strokeWidth={8}
                />
              </Tooltip>
              <span className="title">{module?.name?.[countryCode] || module?.name}</span>
            </div>
          </div>

          <div className="item-actions">
            {isAllocated && <Blob text="Running" color="green" />}

            {displayLabMainActionButton()}
          </div>
        </div>

        <div className={`collapse-body ${open && 'is-open'}`}>
          {(isStarted || isFinished) && (
            <div className="item-body-actions">
              <Button danger onClick={resetConfirmationModal} disabled={isLoading} loading={isLoading}>
                Reset Lab
              </Button>
            </div>
          )}
        </div>

        {open && module?.is_available && (
          <p className="collapse-subtitle outside" onClick={() => setOpen(false)}>
            See less
          </p>
        )}
      </Item>
    </>
  )
}

export default ModuleTableItem

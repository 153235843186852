import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children, ...props }) => {
  const { isAuthenticated } = useSelector((state) => state.users)

  if (isAuthenticated === false) {
    return <Navigate to="/login" replace />
  }

  return children || <Outlet />
}

export default PrivateRoute

import React from 'react'
import parse from 'html-react-parser'
import { Image } from 'antd'
import CustomReplacement from '../CustomReplacement'
import HTMLBlock from 'components/HTMLBlock'

const Replacement = ({ contentHtml, customClass }) => {
  let parsed

  if (contentHtml) {
    parsed = parse(contentHtml, {
      trim: true,
      replace: (domNode) => {
        // block replacements
        if (domNode.type === 'tag' && domNode.name === 'p' && domNode?.children?.length === 1) {
          const childNode = domNode.children[0]

          // custom image
          if (childNode.type === 'tag' && childNode.name === 'img') {
            return (
              <Image
                src={childNode.attribs.src}
                preview={{
                  src: childNode.attribs.src,
                }}
              />
            )
          }

          // custom replacements
          if (childNode.type === 'text') {
            const text = childNode.data
            // searches for "{{...}}" patterns
            const regExp = /{{([^{}]*?)}}/g
            const matches = text.match(regExp)

            if (matches?.length) {
              return <CustomReplacement matches={matches} inputText={text} />
            }
          }
        }

        // inline replacements
        if (domNode.type === 'text') {
          const text = domNode.data
          // searches for "{{...}}" patterns
          const regExp = /{{([^{}]*?)}}/g
          const matches = text.match(regExp)

          if (matches?.length) {
            return <CustomReplacement matches={matches} inputText={text} />
          }
        }
      },
    })
  }

  return (
    <div className={customClass}>
      <HTMLBlock content={parsed} />
    </div>
  )
}

export default Replacement

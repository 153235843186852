import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import BaseFormLayout from 'layouts/BaseFormLayout'
import Button from 'components/Button'
import RedeemVoucherForm from './components/RedeemVoucherForm'
import ContentSelection from './components/ContentSelection'
import SignUpForm from 'pages/SignUpPage/components/SignUpForm'
import VoucherOptions from './components/VoucherOptions'
import { resetVouchersState } from 'store/vouchers/actions'
import { LogoHeader, SAlert } from './styles'
// import Logo from 'assets/images/logo-black-vertical.svg'

const RedeemVoucherPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const { currentVoucher } = useSelector((state) => state.vouchers)
  const voucherHasAvailableSlots =
    !currentVoucher?.redeemed_slots || currentVoucher?.redeemed_slots < currentVoucher?.total_slots

  const [isLoading, setIsLoading] = useState(false)

  // const { pathname } = useLocation()
  // const isNewUser = pathname === '/redeem-voucher/new-user'
  // const isExistingUser = pathname === '/redeem-voucher/existing-user'
  // const isContentSelection = pathname === '/redeem-voucher/content-selection'

  const renderTitle = () => {
    // if (isNewUser) {
    //   return <></>
    // }

    if (isAuthenticated && currentVoucher) {
      if (voucherHasAvailableSlots) {
        return <h5 className="title long">You will get access to the following titles:</h5>
      }

      return (
        <div>
          <h5 className="title long">Voucher was already used</h5>
          <Button type="primary" onClick={() => navigate('/')}>
            Back to dashboard
          </Button>
        </div>
      )
    }

    if (!currentVoucher) {
      return (
        <h5 className="title">{!currentVoucher?.code && 'Please enter your voucher code below. Example: XXXX-YYYY'}</h5>
      )
    }
  }

  const renderContent = () => {
    if (!isLoading && !currentVoucher) {
      return <RedeemVoucherForm />
    }

    if (!isAuthenticated) {
      return <SignUpForm voucher={currentVoucher?.code} />
    }

    if (isAuthenticated && currentVoucher?.code && voucherHasAvailableSlots) {
      return <ContentSelection voucherCode={currentVoucher?.code} />
    }

    // return <VoucherOptions voucherCode={currentVoucher?.code} />
  }

  useEffect(() => {
    // if (isContentSelection && !currentVoucher?.code) {
    //   navigate('/redeem-voucher', { replace: true })
    // }
    // if (currentVoucher?.code && isAuthenticated) {
    //   navigate('/redeem-voucher/content-selection', { replace: true })
    // }
  }, [currentVoucher]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(resetVouchersState())
  }, [])

  return (
    <BaseFormLayout isLoading={isLoading}>
      <LogoHeader>
        {/* <img className="logo" src={Logo} alt="Namespace" /> */}

        {currentVoucher?.code && (
          <SAlert
            className="alert"
            message={
              <>
                Voucher: <b>{currentVoucher?.code}</b>
              </>
            }
            type="info"
          />
        )}

        {isAuthenticated && userProfile?.justCreated && (
          <SAlert
            className="alert"
            message={
              <>
                User created!
                <p>
                  • Email: <b>{userProfile?.email}</b>
                </p>
                <p>
                  • Organization: <b>{userProfile?.organization?.domain}</b>
                </p>
              </>
            }
            type="default"
          />
        )}

        {renderTitle()}
      </LogoHeader>

      {renderContent()}
    </BaseFormLayout>
  )
}

export default RedeemVoucherPage

import { SET_ACTIVITY_ERROR } from 'store/activities'
import { MODULE_UPDATED } from 'store/modules'
import { postActivityAttempt, postActivityHint, postActivitySolution } from 'services/activities'
import { postLtiScore } from 'services/lti'
import { showToast } from 'utils/toast'

const createActivityAttempt = (data, launchId) => async (dispatch, getState) => {
  // data = { activityId, moduleAttemptId, selectedAnswerIds, textAnswer }
  try {
    const { currentModule: module } = getState().modules
    const { user_status: { last_module_attempt: moduleAttempt } = {} } = module
    const body = {
      moduleAttemptId: moduleAttempt.id,
      ...data,
    }
    const responseObj = await postActivityAttempt(body)
    const { activity_attempt, module_attempt } = responseObj

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === activity_attempt.activity) {
              return {
                ...a,
                user_status: activity_attempt,
              }
            }

            return a
          }),
        }

        return auxPage
      }),
      user_status: {
        ...module.user_status,
        last_module_attempt: {
          ...module.user_status.last_module_attempt,
          user_status: module_attempt,
        },
      },
    }

    dispatch(MODULE_UPDATED(auxModule))

    // if module progress is 100%, send score via LTI
    if (launchId && module_attempt?.progress === 1) {
      await postLtiScore(launchId)
        .then((res) => showToast('Score reported back to LMS'))
        .catch((err) => showToast('Failed reporting score back to LMS', 'error'))
    }
  } catch (error) {
    console.log(error)
    const { message } = error
    dispatch(SET_ACTIVITY_ERROR(message))
  }
}

const createActivityHint = (data) => async (dispatch, getState) => {
  // data = { activityId }
  try {
    const { currentModule: module } = getState().modules
    const { user_status: { last_module_attempt: moduleAttempt } = {} } = module
    const body = {
      activityId: data.activityId,
      moduleAttemptId: moduleAttempt.id,
    }
    const responseObj = await postActivityHint(body)
    const { hint_html } = responseObj

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === data.activityId) {
              return {
                ...a,
                hint_html,
              }
            }

            return a
          }),
        }

        return auxPage
      }),
    }

    dispatch(MODULE_UPDATED(auxModule))
  } catch (error) {
    console.log(error)
    const { message } = error
    dispatch(SET_ACTIVITY_ERROR(message))
  }
}

const createActivitySolution = (data) => async (dispatch, getState) => {
  // data = { activityId }
  try {
    const { currentModule: module } = getState().modules
    const { user_status: { last_module_attempt: moduleAttempt } = {} } = module
    const body = {
      activityId: data.activityId,
      moduleAttemptId: moduleAttempt.id,
    }
    const responseObj = await postActivitySolution(body)
    const { solution_html } = responseObj

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === data.activityId) {
              return {
                ...a,
                solution_html,
              }
            }

            return a
          }),
        }

        return auxPage
      }),
    }

    dispatch(MODULE_UPDATED(auxModule))
  } catch (error) {
    console.log(error)
    const { message } = error
    dispatch(SET_ACTIVITY_ERROR(message))
  }
}

export { createActivityAttempt, createActivityHint, createActivitySolution }

import React from 'react'
import { Button } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { Container } from './styles'

const ErrorCard = ({ icon, title, text, buttonText, buttonOnClick }) => {
  return (
    <Container>
      {icon || <WarningFilled />}
      <h3 className="title">{title}</h3>
      <p className="text-body">{text}</p>

      {buttonText && (
        <Button type="primary" onClick={buttonOnClick}>
          {buttonText}
        </Button>
      )}
    </Container>
  )
}

export default ErrorCard

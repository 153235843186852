import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPage } from 'store/modules/actions'
import { Button } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'
import ModulePageSelector from '../ModulePageSelector'
import ModulePageContent from '../ModulePageContent'
import ReportIssueModal from '../ReportIssueModal'
import useQueryParam from 'hooks/useQueryParam'
import { Content, BottomActions } from './styles'

const ModuleContent = () => {
  const [page, setPage] = useQueryParam('page')
  const [isReportIssueVisible, setReportIssueVisible] = useState(false)
  const { currentModule: module } = useSelector((state) => state.modules)
  const { currentLab: lab } = useSelector((state) => state.labs)
  const last_module_attempt = module?.user_status?.last_module_attempt
  const labSession = lab?.allocated_session
  const pageContent = module.module_pages?.[page - 1]
  const dispatch = useDispatch()

  const checkPageValidity = () => {
    if (page && !module.module_pages?.[page - 1]) {
      setPage(1)
    }
  }

  useEffect(() => {
    if (page === null) {
      setPage(1)
    }
    checkPageValidity()

    if (page && typeof page !== 'number') {
      const pageBody = {
        page,
        name: pageContent.name,
      }
      dispatch(selectPage(pageBody))
    }
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const container = document.getElementById('module-page-container')
    if (container) {
      container.scrollTop = 0
    }
  }, [pageContent?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Content id="module-page-container">
        <div>
          <ModulePageContent pageContent={pageContent} />

          {/* <BottomActions>
            <Button
              className="feedback-btn"
              type="text"
              icon={<NotificationOutlined />}
              onClick={() => setReportIssueVisible(true)}
            >
              Give feedback
            </Button>
          </BottomActions> */}

          {/* <ReportIssueModal
            isVisible={isReportIssueVisible}
            setIsVisible={setReportIssueVisible}
            moduleId={module.id}
            moduleAttemptId={last_module_attempt?.id}
            labSessionId={labSession?.id}
          /> */}
        </div>
      </Content>
      <ModulePageSelector page={page} setPage={setPage} />
    </>
  )
}

export default ModuleContent

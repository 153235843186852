import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: null,
  userProfile: {},
  error: null,
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    USER_AUTHENTICATION_UPDATED: (state, { payload }) => {
      state.isAuthenticated = payload
    },
    USER_PROFILE_UPDATED: (state, { payload }) => {
      state.userProfile = payload
      state.isAuthenticated = !!payload
      state.error = null
    },
    SET_USERS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_USERS_STATE: () => initialState,
  },
})

export const { USER_AUTHENTICATION_UPDATED, USER_PROFILE_UPDATED, SET_USERS_ERROR, RESET_USERS_STATE } = slice.actions
export default slice.reducer

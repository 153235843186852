import { createSlice } from '@reduxjs/toolkit'
import countries from 'constants/countries'

const slice = createSlice({
  name: 'app',
  initialState: {
    appError: null,
    appNotification: null,
    countryData: JSON.parse(localStorage.getItem('country')) || countries[0],
  },
  reducers: {
    APP_ERROR_UPDATED: (state, { payload }) => {
      state.appError = payload
    },
    APP_NOTIFICATION_UPDATED: (state, { payload }) => {
      state.appNotification = payload
    },
    COUNTRY_UPDATED: (state, { payload }) => {
      state.countryData = payload
    },
  },
})

export const { PAGE_LOADING_UPDATED, APP_ERROR_UPDATED, APP_NOTIFICATION_UPDATED, COUNTRY_UPDATED } = slice.actions
export default slice.reducer

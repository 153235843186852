import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentModule: null,
  currentPage: null,
  moduleReport: null,
  allocatedModules: null,
  error: null,
}

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    MODULE_UPDATED: (state, { payload }) => {
      state.currentModule = payload
      state.error = null
    },
    MODULE_REPORT_UPDATED: (state, { payload }) => {
      state.moduleReport = payload
      state.error = null
    },
    ALLOCATED_MODULES_UPDATED: (state, { payload }) => {
      state.allocatedModules = payload
      state.error = null
    },
    PAGE_UPDATED: (state, { payload }) => {
      state.currentPage = payload
      state.error = null
    },
    RESET_MODULES_STATE: () => initialState,
    SET_MODULE_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  MODULE_UPDATED,
  MODULE_REPORT_UPDATED,
  ALLOCATED_MODULES_UPDATED,
  PAGE_UPDATED,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
} = slice.actions
export default slice.reducer

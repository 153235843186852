import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import Button from 'components/Button'
import { login } from 'store/users/actions'
import { SmileTwoTone, LockTwoTone, BankTwoTone } from '@ant-design/icons'
import { LogoHeader, Form } from '../../styles'
// import Logo from 'assets/images/logo-black-vertical.svg'

const LoginForm = ({ voucher, callback }) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { error: usersError } = useSelector((state) => state.users)

  const [isLoading, setIsLoading] = useState(false)
  const [shouldShowDomainInput, setShouldShowDomainInput] = useState(false)

  const onSubmit = async (data) => {
    setIsLoading(true)
    await dispatch(login(data, callback))
    setIsLoading(false)
  }

  useEffect(() => {
    if (usersError === 'Must provide organization details') {
      setShouldShowDomainInput(true)
    }
  }, [usersError])

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <LogoHeader>
        {/* {!voucher && <img className="logo" src={Logo} alt="Namespace" />} */}
        <h5 className="title">Login to your account</h5>
      </LogoHeader>

      <Controller
        name="username"
        defaultValue=""
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            size="large"
            placeholder="Username or Email address"
            prefix={<SmileTwoTone />}
          />
        )}
      />
      {errors.username && <p className="error-text">{errors.username.message}</p>}

      <Controller
        name="password"
        defaultValue=""
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <Input.Password {...field} type="password" size="large" placeholder="Password" prefix={<LockTwoTone />} />
        )}
      />
      {errors.password && <p className="error-text">{errors.password.message}</p>}

      {shouldShowDomainInput && (
        <Controller
          name="domain"
          defaultValue=""
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Input {...field} type="text" size="large" placeholder="Organization ID" prefix={<BankTwoTone />} />
          )}
        />
      )}
      {errors.domain && <p className="error-text">{errors.domain.message}</p>}

      {usersError && <p className="error-text">{usersError}</p>}

      <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
        Sign in
      </Button>

      <p className="bottom-text">
        <Link to={`/forgot-password`}>Forgot your password?</Link>
      </p>

      {!voucher && (
        <>
          <hr />
          <Link to={'/redeem-voucher'} className="bottom-btn">
            <Button className="voucher-btn" type="primary" size="large">
              Do you have a voucher? Redeem it here
            </Button>
          </Link>
        </>
      )}
    </Form>
  )
}

export default LoginForm

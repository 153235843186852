import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tag, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { Steps } from 'intro.js-react'
import { showToast } from 'utils/toast'
import { selectDevice, selectView } from 'store/labs/actions'
import Activity from '../Activity'
import { Container } from './styles'

const CustomReplacement = ({ matches, inputText }) => {
  const dispatch = useDispatch()
  const [stepsEnabled, setStepsEnabled] = useState({})
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule: module, currentPage } = useSelector((state) => state.modules)
  const { currentLab: lab, currentDevice: device, currentView } = useSelector((state) => state.labs)
  const pageContent = module.module_pages?.[currentPage?.page - 1]
  const activities = pageContent?.activities
  const labSession = lab?.allocated_session
  const devices = labSession?.devices
  let outputArray = [inputText]

  const embedReplacement = (match, replacement) => {
    const result = outputArray?.map((text) => {
      if (typeof text !== 'string') {
        return text
      }

      return text
        .split(match)
        .reduce((r, a, i) => r.concat(a, <span key={match + i}>{replacement}</span>), [replacement])
        .slice(1, -1)
    })

    outputArray = result.flat()
  }

  const renderReplacement = (match) => {
    const parsedData = match
      .replaceAll('{', '')
      .replaceAll('}', '')
      .split('.')
      .map((w) => w.trim())
    const [type, opt1, opt2] = parsedData

    let replacement = (
      <Tag className="embed-property" color="gold">
        Unknown replacement
      </Tag>
    )

    // activity
    if (type === 'activity') {
      const targetActivityId = opt1?.replaceAll('"', '').replaceAll('_', '').trim()
      const activity = activities?.filter((a) => a.id.includes(targetActivityId))[0]

      replacement = <Activity activity={activity} />
    }

    // highlight
    if (type === 'highlight') {
      // do another regExp to avoid removing class dots
      const regExp = /[^\s"']+|"([^"]*)"|'([^']*)'/g
      let matches = match.match(regExp)
      matches = matches
        .map((m) => {
          return m.replaceAll('{', '').replaceAll('}', '').replaceAll('"', '').trim()
        })
        .filter((m) => m.length > 1)

      const element = matches[1]
      const buttonText = matches[2]
      const contentText = matches[3]
      const elementId = `${element}-${buttonText}`

      // console.log(element)
      const steps = [
        {
          element: `${element}`,
          intro: contentText || '',
          tooltipClass: contentText ? 'single-step' : 'hide-tooltip',
        },
      ]

      replacement = (
        <>
          <Button
            className="embed-btn"
            type="primary"
            onClick={() => setStepsEnabled({ ...stepsEnabled, [elementId]: true })}
          >
            {buttonText}
          </Button>

          <Steps
            enabled={stepsEnabled[elementId]}
            steps={steps}
            initialStep={0}
            onExit={() => setStepsEnabled({ ...stepsEnabled, [elementId]: false })}
          />
        </>
      )
    }

    // switch to certain device view
    if (type === 'switch_to') {
      const targetDeviceName = opt1?.replaceAll('"', '').trim()
      const targetViewName = opt2?.replaceAll('"', '').trim()
      const currentDeviceName = device?.device?.name
      const isSameDevice = currentDeviceName?.toLowerCase() === targetDeviceName.toLowerCase()
      const currentViewName = currentView?.name
      const isSameView = currentViewName?.toLowerCase() === targetViewName?.toLowerCase()
      const shouldBlockSwitchToView = !labSession || (isSameDevice && isSameView)

      const goToDevice = () => {
        devices?.forEach((d) => {
          if (d.device.name?.toLowerCase() === targetDeviceName?.toLowerCase()) {
            dispatch(selectDevice(d))

            const auxView =
              targetViewName &&
              d?.views?.find((v) => {
                return v?.name === targetViewName
              })

            if (auxView) {
              dispatch(selectView(auxView))
            }
          }
        })
      }

      replacement = (
        <Tooltip title={isSameView && 'This is your currently active view'}>
          <Button className="embed-btn" type="primary" onClick={goToDevice} disabled={shouldBlockSwitchToView}>
            Switch to {targetDeviceName}
          </Button>
        </Tooltip>
      )
    }

    // device replacements
    if (type === 'devices') {
      const targetDeviceName = opt1?.replaceAll('"', '').trim()
      const targetPropName = opt2?.replaceAll('"', '').trim()
      const auxDevice = devices?.find((d) => d.device.name?.toLowerCase() === targetDeviceName?.toLowerCase())

      replacement = (
        <Tag className="embed-property error" color="gold">
          Value not found
        </Tag>
      )

      if (!auxDevice) {
        replacement = (
          <Tag className="embed-property" color="gold">
            Value not found, lab is off
          </Tag>
        )
      }

      if (auxDevice && auxDevice.status !== 'started') {
        replacement = (
          <Tooltip
            title={`This value is referencing a property from the device ${auxDevice?.device?.name} which is currently off. To proceed, please turn it on.`}
          >
            <Tag className="embed-property error" color="gold">
              Value not found, device is off
            </Tag>
          </Tooltip>
        )
      }

      if (auxDevice && ['ip_addr', 'ip_address'].includes(targetPropName) && auxDevice.ip_address) {
        replacement = (
          <Tooltip title="Copy to clipboard">
            <Tag className="embed-property" color="blue">
              <span
                onClick={() => {
                  navigator.clipboard.writeText(auxDevice.ip_address)
                  showToast('IP copied to clipboard')
                }}
              >
                {auxDevice.ip_address} <CopyOutlined className="copy-icon" />
              </span>
            </Tag>
          </Tooltip>
        )
      }
    }

    // user replacements
    if (type === 'user') {
      const targetPropName = opt1?.replaceAll('"', '').replaceAll('_', '').trim()
      const validReplacements = ['firstname', 'lastname', 'email']

      replacement = (
        <Tag className="embed-property" color="gold">
          Value not found
        </Tag>
      )

      if (!userProfile) {
        replacement = (
          <Tag className="embed-property" color="gold">
            You are not authenticated
          </Tag>
        )
      }

      if (userProfile && validReplacements.includes(targetPropName)) {
        replacement = (
          <Tooltip title="Copy to clipboard">
            <Tag className="embed-property" color="blue">
              <span
                onClick={() => {
                  navigator.clipboard.writeText(userProfile[targetPropName])
                  showToast('Copied to clipboard')
                }}
              >
                {userProfile[targetPropName]} <CopyOutlined className="copy-icon" />
              </span>
            </Tag>
          </Tooltip>
        )
      }
    }

    return embedReplacement(match, replacement)
  }

  matches?.forEach((m) => renderReplacement(m))

  return <Container>{outputArray}</Container>
}

export default CustomReplacement

import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'titles',
  initialState: {
    items: [],
    currentTitle: null,
    error: null,
  },
  reducers: {
    TITLES_UPDATED: (state, { payload }) => {
      state.items = payload
      state.error = null
    },
    TITLE_UPDATED: (state, { payload }) => {
      state.currentTitle = payload
      state.error = null
    },
    SET_TITLE_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { TITLES_UPDATED, TITLE_UPDATED, SET_TITLE_ERROR } = slice.actions
export default slice.reducer

import { isProduction } from 'helpers/env'

const regions = [
  {
    name: 'US East',
    code: 'us-east',
    disabled: false,
  },
  {
    name: 'EU Central',
    code: 'eu-central',
    redirectsTo: isProduction ? 'eu-central' : 'us-east',
    disabled: false,
  },
  {
    name: 'US West',
    code: 'us-west',
    redirectsTo: isProduction ? 'eu-central' : 'us-east',
    disabled: false,
  },
  {
    name: 'Asia Pacific (Singapore)',
    code: 'ap-southeast-1',
    redirectsTo: isProduction ? 'eu-central' : 'us-east',
    disabled: false,
  },
]

export default regions

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Replacement from '../Replacement'
import { Container } from './styles'

const ModulePageContent = ({ pageContent }) => {
  const [animationKey, setAnimationKey] = useState()
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [countryCode])

  const contentHtml = pageContent?.content_html?.[countryCode] || pageContent?.content_html

  return (
    <Container id="module-page-content-container">
      <div key={animationKey} className="module-content">
        <Replacement contentHtml={contentHtml} />
      </div>
    </Container>
  )
}

export default ModulePageContent

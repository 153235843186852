import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Provider as ReduxProvider } from 'react-redux'
import App from 'App'
import store from 'store'
import * as serviceWorker from 'serviceWorker'
import reportWebVitals from 'reportWebVitals'

import 'antd/dist/antd.min.css'
import 'intro.js/introjs.css'
import 'assets/styles/index.scss'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
)

serviceWorker.unregister()

reportWebVitals()

import styled, { keyframes } from 'styled-components'

const floating = keyframes`
  0% {
    transform: translate(0%, 0%); }
  25% {
    transform: translate(0%, 50%); }
  50% {
    transform: translate(0%, 25%); }
  75% {
    transform: translate(0%, 50%); }
  100% {
    transform: translate(0%, 0%); }
`

export const Container = styled.div`
  width: 100%;
  background-color: #f0f2f5 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  .content {
    z-index: 1;
  }

  .circle-1,
  .circle-2,
  .circle-3,
  .circle-4,
  .circle-5,
  .circle-6,
  .circle-7,
  .circle-8 {
    user-select: none;
    pointer-events: none;
  }

  .circle-1 {
    background: #1890ffaa;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 24%;
    right: 12%;
    animation: ${floating} ease-in-out 12s infinite;
  }

  .circle-2 {
    background: #1890ff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 16%;
    left: 24%;
    animation: ${floating} ease-in-out 4s infinite;
  }

  .circle-3 {
    border: 1px solid #1890ffaa;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    bottom: 32%;
    left: 22%;
    animation: ${floating} ease-in-out 10s infinite;
  }

  .circle-4 {
    border: 1px solid #1890ffaa;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    top: 14%;
    right: 22%;
    animation: ${floating} ease-in-out 10s infinite;
  }

  .circle-5 {
    background-color: #1890ff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 42%;
    right: 29%;
    animation: ${floating} ease-in-out 5s infinite;
  }

  .circle-6 {
    background-color: #1890ff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    bottom: 52%;
    right: 7%;
    animation: ${floating} ease-in-out 3s infinite;
  }

  .circle-7 {
    background: #1890ff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 35%;
    left: 4%;
    animation: ${floating} ease-in-out 4s infinite;
  }

  .circle-8 {
    background: #1890ffaa;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 34%;
    left: 8%;
    animation: ${floating} ease-in-out 8s infinite;
  }
`

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from 'antd'
import { ApiOutlined, RightOutlined, CopyOutlined, PoweroffOutlined, SyncOutlined } from '@ant-design/icons'
import { showToast } from 'utils/toast'
import { Dropdown, Menu } from './styles'
import DefaultDeviceIcon from 'assets/images/device/default-icon.png'
import JupyterDeviceIcon from 'assets/images/device/jupyter-icon.png'
import PostgresDeviceIcon from 'assets/images/device/postgres-icon.png'
import MysqlDeviceIcon from 'assets/images/device/mysql-icon.png'
import VscodeDeviceIcon from 'assets/images/device/vscode-icon.png'
import PhpmyadminDeviceIcon from 'assets/images/device/phpmyadmin-icon.png'
import { startDeviceSession, stopDeviceSession, restartDeviceSession, selectDevice } from 'store/labs/actions'

const DeviceSelector = ({ disabled }) => {
  const dispatch = useDispatch()
  const { currentLab: lab, currentDevice: deviceSession } = useSelector((state) => state.labs)
  const { allocated_session: labSession } = lab
  const { devices } = labSession

  const getIcon = (name) => {
    if (name.toLowerCase().includes('jupyter')) {
      return JupyterDeviceIcon
    }

    if (name.toLowerCase().includes('postgres') || name.toLowerCase().includes('pgadmin')) {
      return PostgresDeviceIcon
    }

    if (name.toLowerCase().includes('mysql')) {
      return MysqlDeviceIcon
    }

    if (name.toLowerCase().includes('vscode')) {
      return VscodeDeviceIcon
    }

    if (name.toLowerCase().includes('php')) {
      return PhpmyadminDeviceIcon
    }

    return DefaultDeviceIcon
  }

  const handleToggleDevice = async (deviceSessionId, status) => {
    if (status !== 'started') {
      await dispatch(startDeviceSession(labSession?.id, deviceSessionId))
    } else {
      await dispatch(stopDeviceSession(labSession?.id, deviceSessionId))
    }
  }

  const handleRestartDevice = async (deviceSessionId, status) => {
    if (status !== 'started') {
      return
    }

    await dispatch(restartDeviceSession(labSession?.id, deviceSessionId))
  }

  const handleSwitchDevice = (deviceSession) => {
    dispatch(selectDevice(deviceSession))
  }

  const menuItems = (
    <Menu
      items={devices?.map((ds) => {
        return {
          key: ds.id,
          label: (
            <div className={`${ds.id === deviceSession?.id && 'is-selected'}`}>
              <div className="device-menu-item">
                <img className="icon" src={getIcon(ds.device?.name)} alt={ds.name} />

                <div className="device-info">
                  <p className="device-name">
                    {ds.device.name}
                    <span className={`status ${ds.status}`}></span>
                  </p>
                  {ds.ip_address && (
                    <Tooltip title="Copy to clipboard">
                      <p
                        className="device-ip"
                        onClick={() => {
                          navigator.clipboard.writeText(ds.ip_address)
                          showToast('IP copied to clipboard')
                        }}
                      >
                        {ds.ip_address} <CopyOutlined />
                      </p>
                    </Tooltip>
                  )}
                </div>

                <div className="device-actions">
                  <Tooltip title={`Power ${ds.status === 'started' ? 'off' : 'on'}`}>
                    <Button
                      type="primary"
                      shape="circle"
                      ghost={ds.status === 'started'}
                      disabled={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      loading={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      icon={<PoweroffOutlined />}
                      onClick={() => handleToggleDevice(ds.id, ds.status)}
                    />
                  </Tooltip>

                  <Tooltip title="Restart">
                    <Button
                      type="primary"
                      ghost
                      disabled={ds.status !== 'started'}
                      loading={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      shape="circle"
                      icon={<SyncOutlined />}
                      onClick={() => handleRestartDevice(ds.id, ds.status)}
                    />
                  </Tooltip>

                  <Button
                    type="primary"
                    disabled={!ds.views?.length || ds.id === deviceSession?.id}
                    onClick={() => handleSwitchDevice(ds)}
                  >
                    Switch
                  </Button>
                </div>
              </div>
            </div>
          ),
        }
      })}
    />
  )

  useEffect(() => {
    if (devices?.length && !deviceSession) {
      dispatch(selectDevice(devices[0]))
    }
  }, [devices?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown overlay={menuItems} trigger={['click']} disabled={disabled}>
      <Button id="device-selector" className="device-selector-btn" icon={<ApiOutlined />} disabled={disabled}>
        {deviceSession?.device?.name}
        <RightOutlined />
      </Button>
    </Dropdown>
  )
}
export default DeviceSelector

import styled from 'styled-components'

export const Content = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-bottom: 16px;

  .item {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &:not(:last-child)  {
      border-bottom: 1px solid #d9d9d9;
    }

    p {
      margin: 0px;
    }

    .icon {
      width: 34px;
      margin-right: 10px;
    }
  }
`

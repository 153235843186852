import { TITLES_UPDATED, TITLE_UPDATED, SET_TITLE_ERROR } from 'store/titles'
import { RESET_MODULES_STATE } from 'store/modules'
import { fetchTitles, fetchTitle } from 'services/titles'

const getTitles = () => async (dispatch) => {
  try {
    const titles = await fetchTitles()
    dispatch(TITLES_UPDATED(titles.results))
  } catch (error) {
    const { message } = error
    dispatch(SET_TITLE_ERROR(message))
  }
}

const getTitle = (titleId, cb) => async (dispatch) => {
  try {
    const title = await fetchTitle(titleId)
    dispatch(TITLE_UPDATED(title))

    if (module) {
      dispatch(RESET_MODULES_STATE())
    }

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_TITLE_ERROR(message))
  }
}

export { getTitles, getTitle }

import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from 'themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  max-width: 1600px;
  display: flex;
  padding: 60px 40px;
  margin: 0px auto;

  .info-container {
    max-width: 30%;
    width: 100%;
    margin: 20px 60px 40px 40px;

    .title {
      border-bottom: 2px solid #000;
      padding-bottom: 5px;
      margin-bottom: 30px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 30px;
      animation: 1s ${fadeInAnimation};
    }
  }

  @media ${device.lgDown} {
    display: block;
    padding: 20px;

    .info-container {
      max-width: initial;
      margin: 20px;
    }
  }
`

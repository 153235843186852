import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #E6F7FF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-tabs {
    width: 100%;

    .ant-tabs-nav {
      height: 40px;
      background-color: #fff;
      margin: 5px;
    }

    .ant-tabs-extra-content {
      margin: 0 16px 0 8px;

      .stop-btn {
        border: none;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-spin {
      margin-bottom: 20px;
    }

    .device-stopped-icon {
      color: #FF4D4F;
      font-size: 58px;
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 10px;
    }

    .subtitle {
      color: rgba(0, 0, 0, 0.45);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}`

import getRandomInt from './getRandomInt'

const randomQuotes = [
  {
    author: 'Warren G. Bennis',
    quote:
      'The factory of the future will have only two employees, a man, and a dog. The man will be there to feed the dog. The dog will be there to keep the man from touching the equipment.',
  },
  {
    author: 'Alice Kahn',
    quote: 'For a list of all the ways technology has failed to improve the quality of life, please press three.',
  },
  { author: 'Steve Wozniak, co-founder of Apple Inc.', quote: 'Never trust a computer you can’t throw out a window.' },
  { author: 'Author Unknown', quote: "Never let a computer know you're in a hurry." },
  { author: 'Jeff Pesis', quote: 'Hardware: The parts of a computer system that can be kicked.' },
  {
    author: 'Seymour Cray',
    quote: 'The trouble with programmers is that you can never tell what a programmer is doing until it’s too late.',
  },
  {
    author: 'Dave Barry',
    quote:
      'Microsoft has a new version out, Windows XP, which according to everybody is the ‘most reliable Windows ever.‘ To me, this is like saying that asparagus is ‘the most articulate vegetable ever.‘',
  },
  {
    author: 'J.K. Rowling, author of the Harry Potter books',
    quote: "Never trust anything that can think for itself if you can't see where it keeps its brain.",
  },
  { author: 'Pablo Picasso', quote: 'Computers are useless. They can only give you answers.' },
  {
    author: 'Richard Buckminster Fuller',
    quote: 'Everyone is born a genius, but the process of living de-geniuses them.',
  },
  {
    author: 'Stewart Brand',
    quote: "Once a new technology rolls over you, if you're not part of the steamroller, you're part of the road.",
  },
  { author: 'Frank Lloyd Wright', quote: 'If it keeps up, man will atrophy all his limbs but the push-button finger.' },
  {
    author: 'Max Frisch',
    quote: "Technology…the knack of so arranging the world that we don't have to experience it.",
  },
  {
    author: 'Bill Gates, founder of Microsoft',
    quote:
      'If GM had kept up with technology like the computer industry has, we would all be driving $25 cars that got 1000 MPG.',
  },
  { author: 'Henry David Thoreau', quote: 'Lo! Men have become the tools of their tools.' },
  {
    author: 'Sydney J. Harris',
    quote:
      'The real danger is not that computers will begin to think like men, but that men will begin to think like computers.',
  },
  {
    author: 'Marshall McLuhan',
    quote:
      'The future masters of technology will have to be light-hearted and intelligent. The machine easily masters the grim and the dumb.',
  },
  {
    author: 'Mike Trout',
    quote:
      'Technology is ruled by two types of people: those who manage what they do not understand, and those who understand what they do not manage.',
  },
  { author: 'Arthur C. Clarke', quote: 'Any sufficiently advanced technology is indistinguishable from magic.' },
  {
    author: 'John Tudor',
    quote: 'Technology makes it possible for people to gain control over everything, except over technology.',
  },
  {
    author: 'Andrew Heller',
    quote: 'Technology is like a fish. The longer it stays on the shelf, the less desirable it becomes.',
  },
  {
    author: 'Andy Rooney',
    quote:
      "Computers make it easier to do a lot of things, but most of the things they make it easier to do don't need to be done.",
  },
  {
    author: 'R. Buckminster Fuller',
    quote: 'Humanity is acquiring all the right technology for all the wrong reasons.',
  },
  {
    author: 'Nicholas P. Negroponte',
    quote:
      "It's not computer literacy that we should be working on, but sort of human literacy. Computers have to become human-literate.",
  },
  {
    author: 'Joseph Weizenbaum',
    quote: 'A computer will do what you tell it to do, but that may be much different from what you had in mind.',
  },
  {
    author: 'Albert Einstein',
    quote: 'It has become appallingly obvious that our technology has exceeded our humanity',
  },
  {
    author: 'Elbert Hubbard',
    quote: 'One machine can do the work of 50 ordinary men. No machine can do the work of one extraordinary man.',
  },
  {
    author: 'William M. Kelly',
    quote: 'Man is a slow, sloppy and brilliant thinker; the machine is fast, accurate and stupid.',
  },
  {
    author: 'E.F. Schumacher',
    quote:
      'The system of nature, of which man is a part, tends to be self-balancing, self-adjusting, self-cleansing. Not so with technology.',
  },
  {
    author: 'Steve Jobs',
    quote:
      "The system is that there is no system. That doesn't mean we don't have process. Apple is a very disciplined company, and we have great processes. But that's not what it's about. Process makes you more efficient.",
  },
  {
    author: 'C.P. Snow',
    quote:
      'Technology…is a queer thing. It brings you great gifts with one hand, and it stabs you in the back with the other.',
  },
  {
    author: 'Isaac Asimov',
    quote: 'The saddest aspect of life right now is that science gathers knowledge faster than society gathers wisdom.',
  },
  {
    author: 'Robert M. Pirsig',
    quote: "Technology presumes there's just one right way to do things and there never is.",
  },
  {
    author: 'Havelock Ellis',
    quote:
      'The greatest task before civilization at present is to make machines what they ought to be, the slaves, instead of the masters of men.',
  },
  {
    author: 'John Kenneth Galbraith',
    quote: 'We are becoming the servants in thought, as in action, of the machine we have created to serve us.',
  },
  {
    author: 'Bruce Lee',
    quote:
      'Man, the living creature, the creating individual, is always more important than any established style or system.',
  },
  {
    author: 'Joseph Campbell',
    quote:
      'Is the system going to flatten you out and deny you your humanity, or are you going to be able to make use of the system to the attainment of human purposes?',
  },
  {
    author: 'Bill Gates',
    quote:
      'The first rule of any technology used in a business is that automation applied to an efficient operation will magnify the efficiency. The second is that automation applied to an inefficient operation will magnify the inefficiency.',
  },
]

const getRandomQuote = () => {
  return randomQuotes[getRandomInt(0, randomQuotes.length - 1)]
}

export default getRandomQuote

import styled from 'styled-components'
import { Form as AntdForm } from 'antd'

export const Form = styled(AntdForm)`
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin: 0 auto;

  .ant-input-affix-wrapper {
    margin: 14px 0px;

    .ant-input-prefix {
      margin-right: 8px;

      svg {
        color: ${(props) => props.theme.colors.a};
      }
    }
  }

  .ant-btn {
    width: 100%;
    margin: 8px 0 18px;
  }

  .voucher-input svg {
    transform: rotate(270deg);
  }

  .error-text {
    color: ${(props) => props.theme.colors.red4};
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin: -10px 0px 20px;
  }
`

import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import usePageTracking from 'hooks/usePageTracking'
import PrivateRoute from 'router/PrivateRoute'
import LoginPage from 'pages/LoginPage'
import RedeemVoucherPage from 'pages/RedeemVoucherPage'
import TitlesPage from 'pages/TitlesPage'
import TitlePage from 'pages/TitlePage'
import ModulePage from 'pages/ModulePage'
import ModuleReportPage from 'pages/ModuleReportPage'
// import ErrorPage from 'pages/ErrorPage'
// import NotFoundPage from 'pages/NotFoundPage'

const MyRoutes = () => {
  usePageTracking()

  return (
    <Routes>
      {/* <Route exact path="/error" element={<ErrorPage />} /> */}
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/forgot-password" element={<LoginPage />} />
      <Route exact path="/reset-password" element={<LoginPage />} />
      <Route exact path="/redeem-voucher" element={<RedeemVoucherPage />} />
      <Route exact path="/redeem-voucher/existing-user" element={<RedeemVoucherPage />} />
      <Route exact path="/redeem-voucher/new-user" element={<RedeemVoucherPage />} />
      <Route exact path="/redeem-voucher/content-selection" element={<RedeemVoucherPage />} />
      <Route element={<PrivateRoute />}>
        <Route exact path="/" element={<TitlesPage />} />
        <Route exact path="/title/:titleId" element={<TitlePage />} />
        <Route exact path="/module/:moduleId" element={<ModulePage />} />
        <Route exact path="/module/:moduleId/report" element={<ModuleReportPage />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}

const Router = () => {
  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  )
}

export default Router

import styled from 'styled-components'

export const Container = styled.div`
  background: blue;
  width: 100%;
  display: flex;
  display: block;
  position: relative;

  .progress {
    width: 100%;
    height: 20px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
      z-index: 1;
    }

    .percentage {
      height: inherit;
      background-color: ${(props) => (props.isFinished ? props.theme.colors.cyan4 : props.theme.colors.blue6)};
      position: absolute;
      left: 0;
    }
  }

  .nav {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }
`

import API from 'services/client'
import { apiErrorHandler } from 'helpers/errorHandlers'

const fetchModule = async (moduleId) => {
  const route = `modules/${moduleId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postModuleAttempt = async (moduleId) => {
  const route = `modules/${moduleId}/attempts`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchModuleReport = async (moduleId, moduleAttemptId) => {
  const route = `modules/${moduleId}/attempts/${moduleAttemptId}/report`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchAllocatedModules = async () => {
  const route = `modules/allocated`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchModule, postModuleAttempt, fetchModuleReport, fetchAllocatedModules }

import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from 'themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  animation: 1s ${fadeInAnimation};

  .card {
    width: 450px;
    max-height: 100%;
    background: #fff;
    overflow: auto;

    .header-bg {
      width: 100%;

      @media ${device.lgDown} {
        display: none;
      }
    }

    .header {
      border-bottom: 1px solid #eee;
      padding: 18px 24px;

      .title {
        display: flex;
        align-items: center;
        margin: 0px;

        svg {
          color: ${(props) => props.theme.colors.a};
          font-size: 18px;
          margin-right: 6px;
        }
      }
    }

    .info {
      padding: 24px 24px 0;

      .ant-select {
        width: 100%;
      }
    }

    .bottom-options {
      border-top: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding: 10px 16px;
      margin-top: 20px;

      .error-text {
        color: ${(props) => props.theme.colors.red4};
        margin: 0px;
      }
    }

    .allocated-modules-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 16px 10px;
      margin: 10px 0px 4px;

      .title {
        margin: 0px;
      }

      .allocated-module {
        height: 80px;
        background-color: #f5f5f5;
        padding: 12px 24px;

        .module-name {
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .right-side {
            display: flex;
            justify-content: flex-end;
            gap: 18px;

            button {
              height: 22px;
              font-weight: 400;
              border-bottom: 1px solid;
              line-height: 20px;
              padding: 0px;
            }
          }
        }
      }
    }
  }
`

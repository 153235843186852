import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Spin, Button, Avatar } from 'antd'
import Layout1 from 'layouts/Layout1'
import { getTitles } from 'store/titles/actions'
import DataPlus from 'assets/images/titles/data+.png'
import { MainContainer } from './styles'

const TitlesPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const { items: titles } = useSelector((state) => state.titles)

  const onLoad = async () => {
    setIsLoading(true)
    await dispatch(getTitles())
    setIsLoading(false)
  }

  const goToTitle = (titleId) => {
    navigate(`/title/${titleId}`)
  }

  useEffect(() => {
    onLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      navbar
      showWhitelabel
      mainContent={
        <MainContainer>
          <div className="main-body">
            <h2 className="section-title">Catalog</h2>

            <div className="titles-container">
              {isLoading && (
                <div className="loading-container">
                  <Spin /> <span className="loading-text">Loading catalog</span>
                </div>
              )}

              {!isLoading && !titles?.length && (
                <div>
                  <p>You don't access to any title yet.</p>

                  <Link to={'/redeem-voucher'} className="bottom-btn">
                    <Button className="voucher-btn" type="primary" size="large">
                      Do you have a voucher? Redeem it here
                    </Button>
                  </Link>
                </div>
              )}

              {!isLoading &&
                titles?.map((title, i) => (
                  <div className="title-container" key={title.id}>
                    {title?.name?.includes('Data+') ? (
                      <img className="logo dataplus" src={DataPlus} alt="Data+" />
                    ) : (
                      <Avatar className="icon">
                        {(title.name?.[countryCode] || title.name).substring(0, 2).toUpperCase()}
                      </Avatar>
                    )}

                    <div className="title-info">
                      <h4 className="title-name">{title.name?.[countryCode] || title.name}</h4>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: title?.content_html?.[countryCode] || title?.content_html,
                        }}
                      />
                    </div>
                    <Button type="primary" onClick={() => goToTitle(title.id)}>
                      See lab guides
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </MainContainer>
      }
    ></Layout1>
  )
}

export default TitlesPage

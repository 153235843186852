import { USER_AUTHENTICATION_UPDATED, USER_PROFILE_UPDATED, SET_USERS_ERROR } from 'store/users'
import { postAuthenticateUser, postLogoutUser, postForgotPassword, postResetPassword, getUserMe } from 'services/users'
import { showToast } from '../../utils/toast/index'

const login = (userCredentials, callback) => async (dispatch) => {
  try {
    await postAuthenticateUser(userCredentials)
    dispatch(
      tryAutoLogin(
        () => showToast('Welcome!'),
        () => showToast('User could not be verified', 'error'),
      ),
    )
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    dispatch(SET_USERS_ERROR(error?.message))
  }
}

const tryAutoLogin = (onSuccess, onError) => async (dispatch) => {
  try {
    const user = await getUserMe()

    if (user) {
      dispatch(USER_PROFILE_UPDATED(user))
    }

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    if (onError) {
      onError()
    }

    dispatch(USER_AUTHENTICATION_UPDATED(false))
  }
}

const logout = () => async (dispatch) => {
  try {
    await postLogoutUser()
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    dispatch(USER_PROFILE_UPDATED({}))

    window.location = '/'
    showToast('Have a nice day!')
  } catch (error) {
    showToast(error?.message, 'error')
  }
}

const forgotPassword = (data) => async () => {
  try {
    const res = await postForgotPassword(data)
    showToast('An email has been sent with instructions on how to reset your password', 'success')
    return res
  } catch (error) {
    showToast(error.message, 'error')
    return error
  }
}

const resetPassword = (data) => async () => {
  try {
    const res = await postResetPassword(data)
    showToast('New password set successfully', 'success')
    return res
  } catch (error) {
    showToast(error.message, 'error')
  }
}

export { login, tryAutoLogin, logout, forgotPassword, resetPassword }

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import activitiesReducer from './activities'
import appReducer from './app'
import labsReducer from './labs'
import modulesReducer from './modules'
import titlesReducer from './titles'
import usersReducer from './users'
import vouchersReducer from './vouchers'

const reducer = {
  activities: activitiesReducer,
  app: appReducer,
  labs: labsReducer,
  modules: modulesReducer,
  titles: titlesReducer,
  users: usersReducer,
  vouchers: vouchersReducer,
}

const middleware = [...getDefaultMiddleware()]
const preloadedState = {}

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
})

export default store

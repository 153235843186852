import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLab: null,
  currentDevice: null,
  currentView: null,
  error: null,
}

const slice = createSlice({
  name: 'labs',
  initialState,
  reducers: {
    LAB_UPDATED: (state, { payload }) => {
      state.currentLab = payload

      // if any device was updated and match currentDevice ID, update it
      state.currentLab?.allocated_session?.devices?.forEach((d) => {
        if (state.currentDevice?.id === d.id) {
          state.currentDevice = d
        }
      })

      // if lab has no session, remove device and view
      if (!state.currentLab?.allocated_session) {
        state.currentDevice = null
        state.currentView = null
      }

      state.error = null
    },
    DEVICE_UPDATED: (state, { payload }) => {
      state.currentDevice = payload

      // also update device on lab info
      state.currentLab = {
        ...state.currentLab,
        allocated_session: {
          ...state.currentLab?.allocated_session,
          devices: state.currentLab?.allocated_session?.devices.map((ds) => {
            if (ds.id === state.currentDevice.id) {
              return { ...state.currentDevice }
            }

            return ds
          }),
        },
      }

      state.currentView = null
      state.error = null
    },
    VIEW_UPDATED: (state, { payload }) => {
      state.currentView = payload
      state.error = null
    },
    RESET_LABS_STATE: () => initialState,
    SET_LAB_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { LAB_UPDATED, RESET_LABS_STATE, DEVICE_UPDATED, VIEW_UPDATED, SET_LAB_ERROR } = slice.actions
export default slice.reducer

import { LAB_UPDATED, RESET_LABS_STATE, DEVICE_UPDATED, VIEW_UPDATED, SET_LAB_ERROR } from 'store/labs'
import {
  fetchLab,
  postLabAllocate,
  postLabDeallocate,
  postLabPing,
  postSessionStart,
  postSessionStop,
  postSessionRestart,
} from 'services/labs'
import { ALLOCATED_MODULES_UPDATED } from 'store/modules'

const _FAKE_LOADING_TIME = 10000

const getLab = (labId, cb) => async (dispatch) => {
  try {
    const lab = await fetchLab(labId)
    dispatch(LAB_UPDATED(lab))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  }
}

const allocateLab = (labId, region, powerOnAllDevices, cb) => async (dispatch, getState) => {
  try {
    const { currentLab: lab } = getState().labs
    const { userProfile } = getState().users

    const data = {
      region,
      metadata: {
        user_id: userProfile?.id,
        organization_id: userProfile?.organization?.id,
      },
    }

    const labSession = await postLabAllocate(labId, data)
    const auxLab = {
      ...lab,
      allocated_session: labSession,
    }

    dispatch(LAB_UPDATED(auxLab))

    if (powerOnAllDevices) {
      auxLab.allocated_session?.devices?.forEach((ds) => {
        dispatch(startDeviceSession(labSession?.id, ds.id))
      })
    }

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
    // showToast(message, 'error')
  }
}

const deallocateLab = (labId, labSessionId, deallocateReason) => async (dispatch, getState) => {
  try {
    const { allocatedModules } = getState().modules
    const { currentLab: lab } = getState().labs

    await postLabDeallocate(labId, labSessionId, deallocateReason)
    const auxLab = {
      ...lab,
      allocated_session: null,
    }

    dispatch(LAB_UPDATED(auxLab))

    const auxAllocatedModules = allocatedModules
      ? allocatedModules.filter((m) => m.lab_session_id !== labSessionId)
      : []
    dispatch(ALLOCATED_MODULES_UPDATED(auxAllocatedModules))
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
    // showToast(message, 'error')
  }
}

const pingLab = (labId, labSessionId) => async () => {
  try {
    await postLabPing(labId, labSessionId)
  } catch (error) {
    const { message } = error
    console.log('Error in pingLab: ', message)
  }
}

const selectDevice = (device) => async (dispatch) => {
  dispatch(DEVICE_UPDATED(device))
}

const selectView = (view) => async (dispatch) => {
  dispatch(VIEW_UPDATED(view))
}

const setDeviceSessionStatus = (deviceSessionId, status) => (dispatch, getState) => {
  const { currentLab: lab } = getState().labs

  const auxLab = {
    ...lab,
    allocated_session: {
      ...lab?.allocated_session,
      devices: lab?.allocated_session?.devices.map((ds) => {
        if (ds.id === deviceSessionId) {
          return {
            ...ds,
            status,
          }
        }

        return ds
      }),
    },
  }

  dispatch(LAB_UPDATED(auxLab))
}

const startDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'starting'))
    const deviceSession = await postSessionStart(labSessionId, deviceSessionId)

    setTimeout(() => {
      const { currentLab: lab } = getState().labs

      const auxLab = {
        ...lab,
        allocated_session: {
          ...lab?.allocated_session,
          devices: lab?.allocated_session?.devices.map((ds) => {
            if (ds.id === deviceSession.id) {
              return deviceSession
            }

            return ds
          }),
        },
      }

      dispatch(LAB_UPDATED(auxLab))
    }, _FAKE_LOADING_TIME)
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  }
}

const stopDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'stopping'))
    const deviceSession = await postSessionStop(labSessionId, deviceSessionId)

    const { currentLab: lab } = getState().labs

    const auxLab = {
      ...lab,
      allocated_session: {
        ...lab?.allocated_session,
        devices: lab?.allocated_session?.devices.map((ds) => {
          if (ds.id === deviceSession.id) {
            return deviceSession
          }

          return ds
        }),
      },
    }

    dispatch(LAB_UPDATED(auxLab))
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  }
}

const restartDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'restarting'))
    const deviceSession = await postSessionRestart(labSessionId, deviceSessionId)

    setTimeout(() => {
      const { currentLab: lab } = getState().labs

      const auxLab = {
        ...lab,
        allocated_session: {
          ...lab?.allocated_session,
          devices: lab?.allocated_session?.devices.map((ds) => {
            if (ds.id === deviceSession.id) {
              return deviceSession
            }

            return ds
          }),
        },
      }

      dispatch(LAB_UPDATED(auxLab))
    }, _FAKE_LOADING_TIME)
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  }
}

const resetLabsState = () => (dispatch) => {
  dispatch(RESET_LABS_STATE())
}

export {
  getLab,
  allocateLab,
  deallocateLab,
  pingLab,
  selectDevice,
  selectView,
  setDeviceSessionStatus,
  startDeviceSession,
  stopDeviceSession,
  restartDeviceSession,
  resetLabsState,
}

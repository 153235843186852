import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'
import { device } from 'themes/breakpoints'

export const Layout = styled(AntLayout)`
  height: 100vh;
  overflow-y: auto;

  .whitelabel-logo {
    width: 124px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
    z-index: 0;

    @media ${device.lgDown} {
      width: 84px;
      bottom: 12px;
      right: 12px;
    }
  }
`

export const Header = styled(Layout.Header)`
  width: 100%;
  background-color: #000;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 1;

  .ant-menu {
    background-color: #000;
    border: 0px;
  }

  .left-content {
    .logo-container {
      height: 100%;
      display: flex;
      align-items: center;

      .logo {
        display: block;
        width: 26px;
      }
    }
  }

  .right-content {
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;

    .ant-menu {
      flex: auto;
      justify-content: flex-end;
    }

    .country-selector {
      min-width: 110px;
      margin: 0px -16px;

      .ant-select-selection-item {
        color: #fff;
        font-weight: 500;
      }

      .ant-select-arrow {
        color: #fff;
      }
    }
  }

  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
  }

  .ant-menu,
  .ant-menu-menu-title,
  .ant-menu-title-content a,
  .ant-menu-submenu-title,
  .ant-menu-submenu-content a {
    color: #fff !important;
    font-weight: 500;
  }

  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu:hover  {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`

export const Content = styled(Layout.Content)`
  width: 100%;
  display: flex;

  @media ${device.lgDown} {
    .reflex-container {
      flex-direction: column;
    }

    .left-pane,
    .right-pane {
      flex: initial !important;
    }

    .right-pane {
      height: initial;
    }

    .reflex-splitter {
      display: none;
    }
  }
`

export const MainContent = styled(Layout.Content)`
  width: 100%;
  flex: 1 1 20em;
`

export const SecondaryContent = styled(Layout.Content)`
  width: 100%;
  flex: 2 1 20em;
  overflow: auto;
`

export const ChildrenContent = styled(Layout.Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`

import styled from 'styled-components'

export const Item = styled.div`
  background-color: ${(props) => (props.isDisabled ? '#eaeaea' : '#fff')};
  border: 1px solid #d9d9d9;
  border-top: 0px;

  .collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .item-info {
      flex: 3 1;

      .collapse-title {
        color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.45)' : '#000')};
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0px;
        ${(props) => props.isDisabled && 'cursor: not-allowed;'}

        .lab-progress {
          margin: 0 14px 0 0;

          .ant-progress-circle  {
            background-color: ${(props) => (props.isFinished ? props.theme.colors.cyan6 : '#fff')};
          }

          .ant-progress-text {
            color: ${(props) => {
              if (props.isFinished) return '#fff'
              if (props.isNotStarted) return 'rgba(0,0,0,.3)'
              return 'rgba(0, 0, 0, 0.85)'
            }};
          }
        }

        .title {
          max-width: 80%;
        }
      }
    }

    .lab-review-btn {
      font-size: 24px;
    }

    .item-actions {
      flex: 1 1;
      display: flex;
      align-items: center;
      gap: 10px;
      ${(props) => props.isDisabled && 'cursor: not-allowed;'}

      p,
      svg {
        color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.45)' : '#000')};
        margin: 0px;
      }
    }

    .item-actions {
      justify-content: flex-end;

      .main-btn {
        width: 96px;
      }
    }

    &.started {
      .item-info {
        .collapse-title {
          .lab-progress {
            /* color: #fff;
            background-color: #3390ff;
            */
          }
        }
      }
    }

    &.finished {
      .item-info {
        .collapse-title {
          .lab-progress {
            /* color: #fff;
            background-color: #52c41a;
            */
          }
        }
      }
    }

    &.is-open {
      .collapse-subtitle {
        opacity: 0;
        cursor: default;
      }

      /* ${(props) =>
        !props.isDisabled &&
        `
        .lab-progress {
          color: #fff !important;
          background-color: ${props.theme.colors.a};
        }
      `}; */
    }
  }

  .collapse-subtitle {
    color: rgba(0, 0, 0, 0.45);
    text-decoration: underline;
    margin: 0px;
    margin-left: 40px;
    cursor: pointer;

    &.outside {
      margin-left: 50px;
      margin-bottom: 14px;
    }
  }

  .collapse-body {
    height: 0px;
    overflow: hidden;
    padding: 0px 10px 0px 50px;
    transition: 0.3s all;

    &.is-open {
      ${(props) =>
        !props.isDisabled &&
        `
        height: initial;
        padding: 10px 10px 10px 50px;
      `};
    }

    .item-body-actions {
      text-align: right;
    }

    .ant-timeline-item-content {
      color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.45)' : '#000')};
    }

    .ant-timeline-item-head {
      background-color: transparent;
    }
  }
`

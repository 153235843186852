import React, { useEffect } from 'react'

import prism from 'utils/prism'
import './styles.scss'

const HTMLBlock = ({ content }) => {
  useEffect(() => {
    prism.highlightAll()
  }, [content])

  if (typeof content === 'object') {
    return content
  }

  return (
    <div
      className="markdown-block"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}

export default HTMLBlock

import { APP_ERROR_UPDATED, APP_NOTIFICATION_UPDATED, COUNTRY_UPDATED } from 'store/app'

const setAppError = (error) => (dispatch) => {
  dispatch(APP_ERROR_UPDATED(error))
}

const setAppNotification = (notification) => (dispatch) => {
  dispatch(APP_NOTIFICATION_UPDATED(notification))
}

const setCountry = (countryData) => (dispatch) => {
  localStorage.setItem('country', JSON.stringify(countryData))
  dispatch(COUNTRY_UPDATED(countryData))
}

export { setAppError, setAppNotification, setCountry }

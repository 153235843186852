import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Modal, Button, Breadcrumb, Dropdown, Menu, Skeleton } from 'antd'
import {
  ArrowLeftOutlined,
  WarningOutlined,
  FileDoneOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { showToast } from 'utils/toast'
import Layout1 from 'layouts/Layout1'
import ModuleContent from './components/ModuleContent'
import LaunchCard from './components/LaunchCard'
import DeviceContent from './components/DeviceContent'
import ErrorCard from 'pages/ErrorPage/components/ErrorCard'
import { getModule, getAllocatedModules, createModuleAttempt, resetModulesState } from 'store/modules/actions'
import { getLab, pingLab, resetLabsState } from 'store/labs/actions'
import { MainContent, SecondaryContent } from './styles'

const ModulePage = () => {
  const dispatch = useDispatch()
  const { moduleId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const { currentModule: module, currentPage, error } = useSelector((state) => state.modules)
  const { currentLab: lab } = useSelector((state) => state.labs)

  const allowNavigation = !userProfile?.organization?.config?.lock_navigation
  const pingTime = (userProfile?.config?.lab_ping_interval_in_seconds || 5) * 1000
  const labSession = lab?.allocated_session
  const last_module_attempt = module?.user_status?.last_module_attempt
  const progress = last_module_attempt?.user_status?.progress
  const isStarted = !!last_module_attempt
  const showError = !isStarted && module
  const showContent = isStarted && module
  const hasLab = module?.lab_id

  const handleModuleLoad = async () => {
    setIsLoading(true)
    await dispatch(getModule(moduleId))
    await dispatch(getAllocatedModules())
    setIsLoading(false)
  }

  const handleLabLoad = async (labId) => {
    setIsLoading(true)
    await dispatch(getLab(labId))
    setIsLoading(false)
  }

  const renderContent = () => {
    if (module) {
      return <ModuleContent />
    }
  }

  const handleResetLab = async () => {
    setIsLoading(true)
    const shouldResetActivities = true
    await dispatch(createModuleAttempt(moduleId, shouldResetActivities))
    setIsLoading(false)
  }

  const resetModule = () => {
    Modal.confirm({
      title: 'Are you sure you want to reset this Lab guide?',
      content: "This action cannot be undone and you'll lose all your Lab guide progress.",
      icon: <ExclamationCircleOutlined />,
      okText: 'Reset',
      cancelText: 'Keep working',
      onOk: handleResetLab,
      okButtonProps: { danger: true, type: 'primary', disabled: isLoading, loading: isLoading },
    })
  }

  const menuItems = (
    <Menu
      items={[
        {
          key: 'report',
          label: 'See report',
          icon: <FileDoneOutlined />,
          onClick: () => navigate(`/module/${module?.id}/report`),
        },
        progress && {
          key: 'reset',
          label: 'Reset activities',
          icon: <SyncOutlined />,
          onClick: resetModule,
          danger: true,
        },
      ]}
    />
  )

  useEffect(() => {
    handleModuleLoad()

    if (error) {
      showToast('There was an error loading module content', 'error')
    }

    return () => {
      if (module) {
        dispatch(resetModulesState())
      }
    }
  }, [moduleId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (module?.lab_id) {
      handleLabLoad(module.lab_id)
    }

    return () => {
      dispatch(resetLabsState())
    }
  }, [module?.lab_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let pingLabInterval
    if (labSession?.status === 'allocated') {
      pingLabInterval = setInterval(() => {
        dispatch(pingLab(lab?.id, labSession?.id))
      }, pingTime)
    }

    return () => {
      if (labSession) {
        clearInterval(pingLabInterval)
      }
    }
  }, [labSession?.status]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      navbar
      isLoading={isAuthenticated === null || !module}
      resizable
      showWhitelabel
      mainContent={
        showContent && (
          <MainContent>
            <div className="header">
              {!isLoading && (
                <>
                  <Breadcrumb>
                    {allowNavigation && (
                      <Breadcrumb.Item>
                        <Link to={`/title/${module?.title?.id}`}>
                          <ArrowLeftOutlined className="back-icon" />
                          Back to Title
                        </Link>
                      </Breadcrumb.Item>
                    )}
                    <Breadcrumb.Item>{module?.name?.[countryCode] || module?.name}</Breadcrumb.Item>
                  </Breadcrumb>

                  <div className="info">
                    <div>
                      <h4 className="module-name">{currentPage?.name?.[countryCode] || currentPage?.name}</h4>
                    </div>

                    <div className="buttons-container">
                      <Dropdown overlay={menuItems} trigger={['click']}>
                        <Button icon={<EllipsisOutlined />} />
                      </Dropdown>
                    </div>
                  </div>
                </>
              )}

              {isLoading && <Skeleton active title={false} loading={isLoading} />}
            </div>
            {isLoading && (
              <div style={{ width: '100%', padding: '20px 38px 20px' }}>
                <Skeleton active loading={isLoading} />
                <Skeleton active loading={isLoading} />
                <Skeleton active loading={isLoading} />
              </div>
            )}
            {!isLoading && module && renderContent()}
          </MainContent>
        )
      }
      secondaryContent={
        showContent &&
        hasLab && (
          <SecondaryContent>
            <div className="mobile-message">
              <WarningOutlined />
              <div>
                <p>Our labs are optimized for Desktop environments.</p>
                <p>Mobile is supported but the experience is limited.</p>
              </div>
            </div>

            <div className="lab-content">
              {module?.lab_id && !lab ? (
                <div className="loading-container">
                  <Skeleton active title={false} paragraph={{ rows: 12 }} />
                </div>
              ) : labSession ? (
                <DeviceContent isFirstLoad={isFirstLoad} setIsFirstLoad={setIsFirstLoad} />
              ) : (
                <LaunchCard setIsFirstLoad={setIsFirstLoad} />
              )}
            </div>
          </SecondaryContent>
        )
      }
    >
      {showError && (
        <ErrorCard
          title={'Lab guide is not started'}
          text={'Start the Lab guide before accessing it'}
          buttonText={'Start lab guide'}
          buttonOnClick={handleResetLab}
        />
      )}
    </Layout1>
  )
}

export default ModulePage

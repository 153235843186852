import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'
import { ExperimentOutlined } from '@ant-design/icons'
import Button from 'components/Button'
import StartLabBg from 'assets/images/start-lab-bg.png'
import regions from 'constants/regions'
import { Content } from './styles'
import LabDevices from '../LabDevices'
import DeviceStop from '../DeviceStop'
import { allocateLab } from 'store/labs/actions'

const LaunchCard = ({ setIsFirstLoad }) => {
  const dispatch = useDispatch()
  const { userProfile } = useSelector((state) => state.users)
  const { currentLab: lab, error: labsError } = useSelector((state) => state.labs)
  const { allocatedModules } = useSelector((state) => state.modules)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(
    regions[0].redirectsTo ? regions[0].redirectsTo : regions[0].code,
  )
  const labGuideIsFinished = false //module?.user_status?.status === 'finished'

  const canRunMultipleLabs = userProfile?.permissions?.includes('labs.run_multiple_labs')

  const handleAllocateLab = async () => {
    const powerOnAllDevices = true

    setIsLoading(true)
    await dispatch(allocateLab(lab?.id, selectedRegion, powerOnAllDevices))
    setIsLoading(false)
    setIsFirstLoad(true)
  }

  const renderAllocatedModules = () => {
    if (!allocatedModules?.length) return

    return (
      <div className="allocated-modules-container">
        <p className="title">Other running labs:</p>

        {allocatedModules?.map((m) => (
          <div key={m?.module_id} className="allocated-module">
            <p className="module-name">{m?.module_name}</p>
            <div className="actions">
              {/* <RunningTime initialDuration={m?.lab_running_time} /> */}

              <div className="right-side">
                <Button type="text">
                  <Link to={`/module/${m?.module_id}`} className="go-to-module-cta">
                    Go to Lab guide
                  </Link>
                </Button>

                <DeviceStop
                  labId={m?.lab_id}
                  labSessionId={m?.lab_session_id}
                  text={'Stop'}
                  type="text"
                  disabled={false}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const handleRegionChange = (code) => {
    const region = regions?.filter((r) => r.code === code)[0]
    if (region.redirectsTo) {
      setSelectedRegion(region.redirectsTo)
      return
    }
    setSelectedRegion(region.code)
  }

  return (
    <Content>
      <div id="launch-lab-card" className="card">
        <img className="header-bg" src={StartLabBg} alt="Start lab" />
        <div className="header">
          <h5 className="title">
            <ExperimentOutlined />
            Lab Preview
          </h5>
        </div>
        <div className="info">
          {lab?.devices && (
            <>
              <div id="lab-devices">
                <p>This lab uses the following devices:</p>

                <LabDevices devices={lab.devices} />
              </div>

              {/* <div id="lab-region">
                <p>Region (Select the one closer to you)</p>

                <Select className="region-select" defaultValue={selectedRegion} onChange={handleRegionChange}>
                  {regions.map((r) => {
                    return (
                      <Option key={r.code} value={r.code} disabled={r.disabled}>
                        {r.name}
                      </Option>
                    )
                  })}
                </Select>
              </div> */}
            </>
          )}
          <Skeleton active title={false} loading={!lab?.devices} />
          <Skeleton active title={false} loading={!lab?.devices} />
        </div>

        <div className="bottom-options">
          {labsError && <p className="error-text">{labsError}</p>}

          {!canRunMultipleLabs && allocatedModules?.length > 0 && (
            <p className="error-text">You can't start this lab because the following labs are running.</p>
          )}

          {!labGuideIsFinished ? (
            <>
              {lab?.devices && (
                <Button
                  id="launch-lab-btn"
                  type="primary"
                  onClick={handleAllocateLab}
                  loading={isLoading}
                  disabled={(!canRunMultipleLabs && allocatedModules?.length) || isLoading}
                >
                  Launch lab
                </Button>
              )}
            </>
          ) : (
            <i>
              <p>This lab is finished</p>
            </i>
          )}
        </div>

        {!canRunMultipleLabs && renderAllocatedModules()}
      </div>
    </Content>
  )
}

export default LaunchCard

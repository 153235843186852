import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Button, Tooltip, Input } from 'antd'
import { BulbOutlined, EyeOutlined } from '@ant-design/icons'
import Replacement from 'pages/ModulePage/components/Replacement'
import { createActivityAttempt, createActivityHint, createActivitySolution } from 'store/activities/actions'
import { Container } from '../../styles'

const InputActivity = ({ activity, isReview, launchId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [answer, setAnswer] = useState(activity?.user_status?.submitted_answer?.text_answer || '')
  const {
    countryData: { code: countryCode },
  } = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const isActivitySubmitted = !!activity.user_status?.submitted_answer_at
  const answerSubmittedAt = isActivitySubmitted && activity.user_status?.submitted_answer_at
  const isActivityPassed = isActivitySubmitted && activity.user_status?.is_passed

  const solutionRequested = activity.user_status?.solution_requested
  const solutionRequestedAt = solutionRequested && activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission =
    solutionRequested && dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequested = activity.user_status?.hint_requested
  const hintRequestedAt = hintRequested && activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = hintRequested && dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const handleSubmitActivity = () => {
    const data = {
      activityId: activity.id,
      textAnswer: answer,
    }

    setIsLoading(true)
    dispatch(createActivityAttempt(data, launchId))
  }

  const handleRequestHint = () => {
    const data = {
      activityId: activity.id,
    }

    setIsLoading(true)
    dispatch(createActivityHint(data))
  }

  const handleRequestSolution = () => {
    const data = {
      activityId: activity.id,
    }

    setIsLoading(true)
    dispatch(createActivitySolution(data))
  }

  const activityActions = (
    <div className="activity-actions">
      {activity?.has_hint &&
        (!isReview ||
          (isReview && activity?.hint_html && (!isActivitySubmitted || isHintRequestedBeforeSubmission))) && (
          <Tooltip title={activity?.hint_html ? 'Hint viewed' : 'View hint'}>
            <Button
              className="hint-btn"
              shape="circle"
              icon={<BulbOutlined />}
              onClick={handleRequestHint}
              loading={isLoading}
              disabled={activity?.hint_html || isLoading}
            />
          </Tooltip>
        )}

      {activity?.has_solution &&
        (!isReview ||
          (isReview && activity?.solution_html && (!isActivitySubmitted || isSolutionRequestedBeforeSubmission))) && (
          <Tooltip title={activity?.solution_html ? 'Solution viewed' : 'View solution'}>
            <Button
              className="solution-btn"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={handleRequestSolution}
              loading={isLoading}
              disabled={activity?.solution_html || isLoading}
            />
          </Tooltip>
        )}
    </div>
  )

  useEffect(() => {
    if (activity && isLoading) {
      setIsLoading(false)
    }
  }, [activity]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container isReview={isReview}>
      <div className="activity-container">
        <div className="activity-content">
          <Replacement
            contentHtml={activity?.content_html?.[countryCode] || activity?.content_html}
            customClass={'activity-content-markdown'}
          />

          {!isReview && activityActions}
        </div>

        {!isReview && activity?.hint_html && (
          <div className="info-block">
            <Replacement contentHtml={activity?.hint_html?.[countryCode] || activity?.hint_html} />
          </div>
        )}

        {!isReview && activity?.solution_html && (
          <div className="info-block">
            <Replacement contentHtml={activity?.solution_html?.[countryCode] || activity?.solution_html} />
          </div>
        )}

        <Input
          className="activity-input"
          value={answer}
          onChange={(evt) => {
            setAnswer(evt.target.value)
          }}
          placeholder={isReview || isActivitySubmitted ? '' : 'Enter your answer'}
          disabled={isReview || isActivitySubmitted || isLoading}
        />
      </div>

      <div className="review-status-block">
        <span>
          {!isReview && (
            <Button
              type="primary"
              onClick={handleSubmitActivity}
              loading={isLoading}
              disabled={isActivitySubmitted || isLoading}
            >
              {isActivitySubmitted ? 'Submitted' : 'Submit'}
            </Button>
          )}

          {isActivitySubmitted && (
            <Button type="dashed" className={`activity-status-btn ${isActivityPassed ? 'correct' : 'incorrect'}`}>
              {isActivityPassed ? 'Correct' : 'Incorrect'}
            </Button>
          )}
        </span>

        {isReview && !isActivitySubmitted && (
          <Button type="dashed" className={'activity-status-btn not-actioned'}>
            Not actioned
          </Button>
        )}

        {isReview && activityActions}
      </div>
    </Container>
  )
}

export default InputActivity

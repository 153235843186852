import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { MailTwoTone, BankTwoTone } from '@ant-design/icons'
import Button from 'components/Button'
import { forgotPassword } from 'store/users/actions'
import { LogoHeader, Form } from '../../styles'
// import LogoSm from 'assets/images/logo-black-sm.svg'

const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [shouldShowDomainInput, setShouldShowDomainInput] = useState(false)

  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    if (isSent) return

    setIsLoading(true)
    const res = await dispatch(forgotPassword(data))
    setIsLoading(false)

    const needsDomain = res?.message === 'Must provide organization details'

    if (needsDomain) {
      setShouldShowDomainInput(true)
    }

    if (res?.http_code !== 500 && !needsDomain) {
      setIsSent(true)
    }
  }

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)} autoComplete="off">
      <LogoHeader>
        {/* <img className="logo sm" src={LogoSm} alt="Namespace" /> */}
        <h3>Reset your password</h3>
      </LogoHeader>

      {!isSent && (
        <>
          <p>Enter the email associated with your account and we will send you a password reset link.</p>

          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Input {...field} type="email" size="large" placeholder="Your email address" prefix={<MailTwoTone />} />
            )}
          />
          {errors.email && <p className="error-text">{errors.email.message}</p>}

          {shouldShowDomainInput && (
            <Controller
              name="domain"
              defaultValue=""
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Input {...field} type="text" size="large" placeholder="Organization ID" prefix={<BankTwoTone />} />
              )}
            />
          )}
          {errors.domain && <p className="error-text">{errors.domain.message}</p>}

          <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
            Send password reset email
          </Button>
        </>
      )}

      {isSent && (
        <>
          <p>
            Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your
            spam folder.
          </p>

          <Link to={'/login'}>
            <Button type="primary" size="large">
              Return to sign in
            </Button>
          </Link>
        </>
      )}

      {!isSent && (
        <p className="bottom-text">
          Already have an account? <Link to={`/login${window?.location?.search}`}>Login</Link>
        </p>
      )}
    </Form>
  )
}

export default ForgotPasswordForm

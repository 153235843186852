import { Button as AntdButton } from 'antd'
import styled from 'styled-components'

export const Button = styled(AntdButton)`
  height: auto;

  &.ant-btn-primary {
    border-color: ${(props) => props.theme.colors.a};
    background: ${(props) => props.theme.colors.a};
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  &.ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
`

import API from 'services/client'
import { apiErrorHandler } from 'helpers/errorHandlers'

const postRegisterUser = async (userData) => {
  const route = `users/signup`
  return API.post(route, userData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAuthenticateUser = async (userCredentials) => {
  const route = `users/login`
  return API.post(route, userCredentials)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postLogoutUser = async () => {
  const route = `users/logout`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postForgotPassword = async (data) => {
  const route = `users/password-reset`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postResetPassword = async (data) => {
  const route = `users/password-reset/confirm`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getUserMe = async () => {
  const route = `users/me`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { postRegisterUser, postAuthenticateUser, postLogoutUser, postForgotPassword, postResetPassword, getUserMe }

import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    base: {
      white: '#fff',
      black: '#000',
      success: '#2ecc71',
      successDark: '#27ae60',
      danger: '#e74c3c',
      dangerDark: '#c0392b',
      warning: '#f1c40f',
      warningDark: '#f39c12',
    },
    //
    a100: '#f9f9f9',
    a300: '#eeeeee',
    a: '#000',
    a650: '#BFBFBF',
    a700: '#8C8C8C',
    a900: '#444',
    // cyan
    cyan4: '#5CDBD3',
    cyan6: '#13c2c2',
    // green
    green5: '#73d13d',
    // red
    red1: '#fff1f0',
    red4: '#FF4D4F',
    // Daybreak Blue
    blue1: '#e6f7ff',
    blue2: '#bae7ff',
    blue6: '#1890ff',
    // Calendula Gold
    gold6: '#faad14',
    //
    b100: '#FFB733',
    b300: '#E8912E',
    b: '#FF8940',
    b700: '#E85A2E',
    b900: '#FF4633',
    //
    c100: '#FF5140',
    c300: '#E83A5A',
    c: '#FF4DC7',
    c700: '#D73AE8',
    c900: '#B840FF',
  },
  // fonts: ['sans-serif', 'Roboto'],
  // fontSizes: {
  //   small: '1em',
  //   medium: '2em',
  //   large: '3em',
  // },
}

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default Theme

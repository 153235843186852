import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  overflow: auto;
  padding: 10px 38px 20px;

  .module-content {
    animation: 1s ${fadeInAnimation};

    img {
      width: 100%;
      max-width: 1024px;
    }

    ul {
      li {
        line-height: 24px;
        padding-bottom: 4px;
      }
    }
  }
`

import React from 'react'
import { Skeleton } from 'antd'
import { ModulesTableContainer } from './styles'
import ModulesTableItem from '../ModulesTableItem'

const ModulesTable = ({ isLoading, modules }) => {
  let skills = {}
  modules?.forEach((m) => {
    if (skills?.[m?.skill?.id]) {
      skills?.[m?.skill?.id]?.modules?.push(m)
    } else {
      skills[m?.skill?.id] = {
        skill: m?.skill,
        modules: [m],
      }
    }
  })

  return (
    <ModulesTableContainer>
      <div className="header">
        <h4 className="title">Lab guides</h4>
      </div>
      <div className="collapse">
        {isLoading ? (
          <div className="loading-container">
            <Skeleton active title={false} paragraph={{ rows: 2 }} loading={isLoading} style={{ marginBottom: 20 }} />
            <Skeleton active title={false} paragraph={{ rows: 2 }} loading={isLoading} style={{ marginBottom: 20 }} />
            <Skeleton active title={false} paragraph={{ rows: 2 }} loading={isLoading} style={{ marginBottom: 20 }} />
          </div>
        ) : (
          Object.entries(skills)?.map((data, i) => {
            const { skill, modules } = data[1]

            return (
              <div key={skill.id}>
                <h4 className="skill-name">{skill.name}</h4>

                {modules.map((m, j) => (
                  <ModulesTableItem module={m} number={j + 1} showInfo />
                ))}
              </div>
            )
          })
        )}
      </div>
    </ModulesTableContainer>
  )
}

export default ModulesTable

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, Menu, Modal, Dropdown, Select } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import OverlappedLoader from 'components/OverlappedLoader'
import LogoSm from 'assets/images/logo-white-sm.svg'
import DefaultAvatar from 'assets/images/default-avatar.png'
import { logout } from 'store/users/actions'
import { setCountry } from 'store/app/actions'
import countries from 'constants/countries'

import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import 'react-reflex/styles.css'

import { Layout, Header, Content, MainContent, SecondaryContent, ChildrenContent } from './styles'
const { Option } = Select

const Layout1 = ({ navbar, mainContent, secondaryContent, resizable, isLoading, showWhitelabel, children }) => {
  const dispatch = useDispatch()

  const { countryData } = useSelector((state) => state.app)
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const { organization } = userProfile
  const allowNavigation = !userProfile?.organization?.config?.lock_navigation

  const handleLogout = () => {
    Modal.confirm({
      title: 'Sign out',
      content: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, sign out',
      cancelText: 'Keep working',
      onOk: () => dispatch(logout()),
      okButtonProps: { danger: true, type: 'primary', disabled: isLoading, loading: isLoading },
    })
  }

  // const handleCountryToggle = (countryCode) => {
  //   const auxCountryData = countries.find((c) => c.code === countryCode)
  //   dispatch(setCountry(auxCountryData))
  // }

  const menuItems = [
    // {
    //   key: 'country',
    //   label: (
    //     <Select value={countryData.code} className="country-selector" bordered={false} onChange={handleCountryToggle}>
    //       {countries.map((c) => (
    //         <Option key={c.code} value={c.code} label={c.label}>
    //           <span role="img" aria-label={c.label}>
    //             {c.icon}
    //           </span>{' '}
    //           {c.label}
    //         </Option>
    //       ))}
    //     </Select>
    //   ),
    // },
    isAuthenticated
      ? {
          key: 'profile',
          label: (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu
                  items={[
                    {
                      key: 'redeemVoucher',
                      label: <Link to="/redeem-voucher">Redeem voucher</Link>,
                    },
                    {
                      key: 'signOut',
                      label: <span onClick={handleLogout}>Sign out</span>,
                    },
                  ]}
                />
              }
            >
              <span style={{ padding: '8px 0px' }}>
                <Avatar src={DefaultAvatar} style={{ marginRight: 8 }} />
                <span>
                  {userProfile?.first_name?.toUpperCase()} {userProfile?.last_name?.toUpperCase()}
                </span>
              </span>
            </Dropdown>
          ),
        }
      : {
          key: 'login',
          label: <Link to="/login">Login</Link>,
        },
  ]

  return (
    <Layout>
      {navbar && (
        <Header>
          <div className="left-content">
            <div className="logo-container">
              <Link to={allowNavigation && '/'}>
                <img className="logo" src={LogoSm} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="right-content">
            <Menu key="userProfile" mode="horizontal" items={menuItems} />
          </div>
        </Header>
      )}

      {isLoading && <OverlappedLoader />}

      <Content>
        <ReflexContainer orientation="vertical">
          {mainContent && (
            <ReflexElement
              className="left-pane"
              style={{ display: 'flex' }}
              size={secondaryContent && 600}
              minSize={400}
            >
              <MainContent>{mainContent}</MainContent>
            </ReflexElement>
          )}

          {secondaryContent && resizable && (
            <ReflexSplitter style={{ backgroundColor: '#777', border: 'none', width: 6 }} />
          )}

          {secondaryContent && (
            <ReflexElement className="right-pane" style={{ display: 'flex' }} minSize={600}>
              <SecondaryContent>{secondaryContent}</SecondaryContent>
            </ReflexElement>
          )}

          {children && <ChildrenContent>{children}</ChildrenContent>}
        </ReflexContainer>
      </Content>

      {showWhitelabel && organization?.whitelabel_is_enabled && organization?.whitelabel_logo_image_url && (
        <>
          <img
            className="whitelabel-logo"
            draggable={false}
            src={organization?.whitelabel_logo_image_url}
            alt={organization?.name}
          />
        </>
      )}
    </Layout>
  )
}

export default Layout1

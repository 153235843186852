import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    const GAID = process.env?.REACT_APP_GA_MEASUREMENT_ID

    if (GAID) {
      ReactGA.initialize(GAID)
      ReactGA.send({ hitType: 'pageview', page: `${location.pathname}${location.search}` })
    }
  }, [location])
}

export default usePageTracking

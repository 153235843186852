import API from 'services/client'
import { apiErrorHandler } from 'helpers/errorHandlers'

const fetchTitles = async () => {
  const route = `titles`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchTitle = async (titleId) => {
  const route = `titles/${titleId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchTitles, fetchTitle }

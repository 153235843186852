import styled from 'styled-components'
import { Form as AntdForm } from 'antd'

export const LogoHeader = styled.div`
  text-align: center;

  .logo {
    max-width: 176px;
    width: 100%;
    image-rendering: crisp-edges;
    margin-bottom: 20px;

    &.sm {
      max-width: 36px;
    }
  }

  .title {
    color: #00000073;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const Form = styled(AntdForm)`
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin: 0 auto;

  .ant-input-affix-wrapper {
    margin: 14px 0px;

    .ant-input-prefix {
      margin-right: 8px;

      svg {
        color: ${(props) => props.theme.colors.a};
      }
    }
  }

  .ant-btn {
    width: 100%;
    margin: 8px 0 18px;
  }

  .error-text {
    color: ${(props) => props.theme.colors.red4};
    font-size: 12px;
    text-align: center;
    margin: -12px 4px 4px;
  }

  .bottom-text {
    color: ${(props) => props.theme.colors.a};
    font-size: 13px;
    line-height: 24px;
    margin: -12px 0px 20px;

    > a {
      color: ${(props) => props.theme.colors.a};

      &:hover  {
        text-decoration: underline;
      }
    }
  }

  .bottom-btn {
    color: ${(props) => props.theme.colors.a};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    &:hover  {
      text-decoration: underline;
    }
  }

  hr {
    border-top: 1px solid #bbb;
  }

  .voucher-btn {
    background-color: ${(props) => props.theme.colors.blue6};
    border-color: ${(props) => props.theme.colors.blue6};
    margin-top: 18px;
  }
`

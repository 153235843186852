import React from 'react'
import { Content } from './styles'
import DefaultDeviceIcon from 'assets/images/device/default-icon-rounded.png'
import JupyterDeviceIcon from 'assets/images/device/jupyter-icon-rounded.png'
import PostgresDeviceIcon from 'assets/images/device/postgres-icon-rounded.png'
import MysqlDeviceIcon from 'assets/images/device/mysql-icon-rounded.png'
import VscodeDeviceIcon from 'assets/images/device/vscode-icon-rounded.png'
import PhpmyadminDeviceIcon from 'assets/images/device/phpmyadmin-icon-rounded.png'

const LabDevices = ({ devices }) => {
  const getIcon = (name) => {
    if (name.toLowerCase().includes('jupyter')) {
      return JupyterDeviceIcon
    }

    if (name.toLowerCase().includes('postgres') || name.toLowerCase().includes('pgadmin')) {
      return PostgresDeviceIcon
    }

    if (name.toLowerCase().includes('mysql')) {
      return MysqlDeviceIcon
    }

    if (name.toLowerCase().includes('vscode')) {
      return VscodeDeviceIcon
    }

    if (name.toLowerCase().includes('php')) {
      return PhpmyadminDeviceIcon
    }

    return DefaultDeviceIcon
  }

  return (
    <Content id="devices-box">
      {devices?.map((d) => {
        return (
          <div key={d.id} className="item">
            <img className="icon" src={getIcon(d.name)} alt={d.name} />
            <p>{d.name}</p>
          </div>
        )
      })}
    </Content>
  )
}

export default LabDevices

import styled from 'styled-components'

export const PasswordContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 0px 12px;

  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    margin: 14px -12px !important;
    width: calc(100% + 24px);
  }

  .password-strength-meter {
    height: 4px;
    width: calc(100% + 24px);
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0px;
    margin: -12px 0px -12px -12px;
  }

  .password-strength-meter::before {
    content: '';
    background-color: ${(props) => ['#e74c3c', '#e67e22', '#f39c12', '#f1c40f', '#2ecc71'][props.level - 1] || ''};
    height: 100%;
    width: ${(props) => (props.level / 5) * 100}%;
    display: block;
    transition: width 0.2s;
  }
`

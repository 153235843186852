import React from 'react'
import Layout1 from 'layouts/Layout1'
import { Container } from './styles'

const BaseFormLayout = ({ isLoading, children }) => {
  return (
    <Layout1
      navbar
      isLoading={isLoading}
      mainContent={
        <Container>
          <span className="content">{!isLoading && children}</span>
          <span className="circle-1" />
          <span className="circle-2" />
          <span className="circle-3" />
          <span className="circle-4" />
          <span className="circle-5" />
          <span className="circle-6" />
          <span className="circle-7" />
          <span className="circle-8" />
        </Container>
      }
    ></Layout1>
  )
}

export default BaseFormLayout

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { LockTwoTone } from '@ant-design/icons'
import PasswordInput from 'components/PasswordInput'
import Button from 'components/Button'
import { resetPassword } from 'store/users/actions'
import { LogoHeader, Form } from '../../styles'
// import LogoSm from 'assets/images/logo-black-sm.svg'

const ResetPasswordForm = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('token')
  const email = urlParams.get('email')?.replaceAll(' ', '+')

  const onSubmit = async (data) => {
    const auxData = {
      email,
      token,
      new_password: data?.password,
      repeated_new_password: data?.repeat_password,
    }

    setIsLoading(true)
    const resetPasswordResponse = await dispatch(resetPassword(auxData))
    setIsLoading(false)

    if (resetPasswordResponse?.message === 'Password changed') {
      navigate('/login', { replace: true })
    }
  }

  useEffect(() => {
    if (!email || !token) {
      navigate('/login', { replace: true })
    }
  }, [urlParams]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)} autoComplete="off">
      <LogoHeader>
        {/* <img className="logo sm" src={LogoSm} alt="Namespace" /> */}
        <h3>Change password for {email}</h3>
      </LogoHeader>

      <div style={{ width: '100%', display: 'inline-block' }}>
        <Controller
          name="password"
          defaultValue=""
          control={control}
          rules={{
            required: 'Password is required',
          }}
          render={({ field }) => {
            const auxProps = { ...field }
            delete auxProps.ref

            return (
              <PasswordInput
                {...auxProps}
                type="password"
                size="large"
                status={errors.password ? 'error' : null}
                placeholder="New password"
                prefix={<LockTwoTone />}
                handleChange={setPasswordError}
              />
            )
          }}
        />
        {errors.password && <p className="error-text">{errors.password.message}</p>}
      </div>

      <Controller
        name="repeat_password"
        defaultValue=""
        control={control}
        rules={{
          required: 'Re-enter your password',
          validate: (value) => value === watch('password') || "Passwords don't match",
        }}
        render={({ field }) => (
          <Input.Password
            {...field}
            type="password"
            size="large"
            status={errors.repeat_password ? 'error' : null}
            placeholder="Confirm password"
            prefix={<LockTwoTone />}
          />
        )}
      />

      <div className="error-container">
        {errors && <p className="error-text">{errors[Object.keys(errors)?.[0]]?.message}</p>}
        {!Object.keys(errors)?.length && passwordError && <p className="error-text">{passwordError}</p>}
      </div>

      <Button type="primary" size="large" htmlType="submit" disabled={passwordError} loading={isLoading}>
        Change password
      </Button>
    </Form>
  )
}

export default ResetPasswordForm

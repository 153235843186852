import styled, { keyframes } from 'styled-components'

const pulseWhite = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const pulseRed = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`

const pulseOrange = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
`

const pulseYellow = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
`

const pulseBlue = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
`

const pulseGreen = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
`

const pulsePurple = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
`

export const Blob = styled.span`
  background: black;
  display: inline-block;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  transform: scale(1);
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse-black 2s infinite;

  &.white {
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: ${pulseWhite} 2s infinite;
  }

  &.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: ${pulseRed} 2s infinite;
  }

  &.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: ${pulseOrange} 2s infinite;
  }

  &.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: ${pulseYellow} 2s infinite;
  }

  &.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: ${pulseBlue} 2s infinite;
  }

  &.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: ${pulseGreen} 2s infinite;
  }

  &.purple {
    background: rgba(142, 68, 173, 1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
    animation: ${pulsePurple} 2s infinite;
  }
`
